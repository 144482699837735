import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { PrivateLayout } from "common/layouts";
import { loggedUser } from "store/features/authSlice";

const PrivateRoute = ({ getSettingData, t, socket }) => {
  const checkLoggedInUser = useSelector(loggedUser);
  const location = useLocation();
  useEffect(() => {
    if (window.localStorage.isShowingError) {
      window.localStorage.removeItem("isShowingError");
    }
  });

  const isUserLogin = checkLoggedInUser.isUserLogin;
  return isUserLogin ? (
    <PrivateLayout getSettingData={getSettingData} t={t} socket={socket}>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
