import client from "../libs/HttpClient";
class AdverstisementService {
  static parentCategoryNameList() {
    return client.get("category/parent-category-name");
  }
  static childCategoryNameList({ params }) {
    return client.get("category/child-category-name", { params });
  }

  static adverstisementList({ params }) {
    return client.get("category/advertisement-list", { params });
  }

  static getCitiesByState({ params }) {
    return client.get("category/cities-list", { params });
  }
}
export { AdverstisementService };
