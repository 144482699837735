import { t } from "i18next";
import React from "react";
import { Helmet } from "react-helmet";
import favIcon from "../../assets/images/favicon.svg";

const CommonHelmet = ({ title, description }) => {
  const titles = t(title) + " | " + t("front_page.url_title_common");
  const descriptions = description || titles;
  return (
    <Helmet>
      <title>{titles}</title>
      <meta name="title" content={titles} />
      <meta name="description" content={descriptions} />
      {/* <meta name="og:title" content={titles} />
      <meta name="og:description" content={descriptions} /> */}
      {favIcon && <link rel="icon" href={favIcon} />}
    </Helmet>
  );
};

export default CommonHelmet;
