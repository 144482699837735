import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/scss/page/_checkout.scss";
import {
  useAddStripeCard,
  useCreatePayment,
  useDeleteStripeCards,
  useGetFinalPrice,
  useGetStripeCards,
  useUpdateRenewStatus,
} from "hooks";
import { toast } from "react-toastify";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Formik } from "formik";
import validationSchema from "./CheckoutValidations";
import { ADS } from "config";
import { LIST_ADS } from "config";
import { PREVIEW } from "config";
import { CommonHelmet } from "common";

function Checkout({ t }) {
  const location = useLocation();
  const navigate = useNavigate();
  const parentCategory = location.state?.parentCategory;
  const subcategoryIds = location.state?.subcategoryIds;
  const advertisement_id = location.state?.advertisement_id;
  const renewStatus = location.state?.renewStatus;
  const [pricingData, setPricingData] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState(30);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [deletingCardId, setDeletingCardId] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedBoosts, setSelectedBoosts] = useState([]);
  const handleCardSelect = (cardId) => {
    setSelectedCardId(cardId);
  };

  const { refetch: doGetcards, isLoading: gettingCards } = useGetStripeCards(
    ({ data: cards }) => {
      setCards(cards.cards);
    }
  );

  const { mutate: doAddCard, isLoading: addingCard } = useAddStripeCard(
    (res) => {
      toast.success(res.message);
      doGetcards();
    }
  );
  const { mutate: doUpdateRenewStatus } = useUpdateRenewStatus(
    (res) => {
      toast.success(res.message);
    },
    (err) => {
      toast.error(err.message);
    }
  );

  const { mutate: doDelete } = useDeleteStripeCards((res) => {
    toast.success(res.message);
    setDeletingCardId(null);
    doGetcards();
  });
  const { mutate: doCreatePayment } = useCreatePayment((res) => {
    if (res.data.next_action != null) {
      window.location.href = res?.data?.next_action?.redirect_to_url?.url;
    } else {
      if (renewStatus == 1) {
        doUpdateRenewStatus({
          advertisement_id: advertisement_id,
          status: renewStatus,
        });
      }
      if (res?.data?.amount == 0) {
        toast.success(t("front_page.created_ads_successfully_txt"));
      } else {
        toast.success(res.message);
      }
      navigate(ADS + LIST_ADS);
    }
  });

  const handleAddCard = async (values) => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: values.cardOwner,
      },
    });

    if (error) {
      console.error("Error creating payment method:", error);
    } else {
      doAddCard({
        payment_method_id: paymentMethod.id,
        card_owner: values.cardOwner,
      });
      setShowModal(false);
    }
  };

  const handleDeleteCard = (id) => {
    setDeletingCardId(id);
    doDelete({ card_id: id });
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);

    if (value == 365) {
      setTotalAmount(totalAmount + Number(pricingData?.total_yearly_price));
    } else {
      setTotalAmount(Number(pricingData?.total_monthly_price));
    }

    setSelectedDuration(value);
  };
  const handleChangeBoost = (event, boost) => {
    const isChecked = event.target.checked;
    const boostPrice = Number(boost?.price);
    if (isChecked) {
      setSelectedBoosts((prev) => [...prev, boost?.boost_post_id]);

      setTotalAmount((prevAmount) => prevAmount + boostPrice);
    } else {
      setSelectedBoosts((prev) =>
        prev.filter((id) => id !== boost?.boost_post_id)
      );
      setTotalAmount((prevAmount) => prevAmount - boostPrice);
    }
  };

  const { data: doGetPrices } = useGetFinalPrice(
    { advertisement_id: advertisement_id },
    (res) => {
      setPricingData(res.data);
      setTotalAmount(res?.data?.total_monthly_price);
    },
    (err) => {
      toast.error(err.message);
    }
  );
  const handlePayment = (card_id) => {
    doCreatePayment({
      amount: totalAmount * 100,
      payment_method_id: card_id,
      validity: selectedDuration,
      return_url: process.env.REACT_APP_WEB_URL + "ads/list-ads",
      advertisement_id: advertisement_id,
      boosts: selectedBoosts,
    });
  };
  return (
    <>
      <CommonHelmet
        title={"front_page.checkout_seo_title"}
        description={"front_page.checkout_seo_title"}
      />
      <Row>
        <Col md={3}>
          <div className="d-flex business-heading">
            <div>
              <Button
                onClick={() =>
                  navigate(ADS + PREVIEW, {
                    state: {
                      advertisement_id: advertisement_id,
                      subcategoryIds: subcategoryIds,
                      parentCategory: parentCategory,
                    },
                  })
                }
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </div>
            <div>
              <h3 className="ms-2">{t("front_page.checkout_title")}</h3>
            </div>
          </div>
        </Col>
      </Row>

      {/* Post Details */}
      <Row className="details-row mt-4 mx-1">
        <Col xs={9} md={10}>
          {t("front_page.checkout_post_details_title")}
        </Col>
        <Col xs={3} md={2} className="text-end">
          {t("front_page.checkout_post_price_txt")}
        </Col>
      </Row>
      <Row className="details-data-row mt-2 mx-1">
        {/* post name */}
        <Col xs={12} className="post-name">
          {pricingData?.ads_data?.post_title}
        </Col>

        {/* category / subcategory */}
        {pricingData?.pricing_data?.map((category, index) => (
          <Row key={index} className="mb-2">
            {" "}
            {/* Added Row for structure and key */}
            <Col xl={10} md={8} className="ps-4 ps-md-5 cat-subcat-name">
              <span>{pricingData?.parent_category_name}</span> &gt;
              <span className="ms-1">{category?.subcategory_name}</span> &gt;
              <span className="ms-1">{pricingData?.city}</span>
            </Col>
            <Col
              xl={2}
              md={4}
              className="text-end cat-sucat-price mt-2 mt-md-0"
            >
              ${" "}
              <span>
                {category?.price != null && category?.price != 0
                  ? category?.price
                  : 0.0}
              </span>{" "}
              {t("front_page.checkout_post_usd_txt")}
            </Col>
          </Row>
        ))}
        <span className="text-secondary ps-4 ps-md-5">
          {t("front_page.checkout_page_note")}
        </span>
        <hr className="mt-3"></hr>

        {/* Boost post option */}
        {pricingData?.boost?.map((bst) => (
          <React.Fragment key={bst.boost_post_id}>
            <Col xs={7} xl={10} className="ps-4 ps-md-5 cat-subcat-name">
              <Form.Check
                inline
                label={bst?.name}
                name="boost_post"
                type="checkbox"
                checked={selectedBoosts.includes(bst?.boost_post_id)}
                onChange={(e) => handleChangeBoost(e, bst)}
                className="custom-check"
              />
            </Col>
            <Col xs={5} xl={2} className="text-end cat-sucat-price">
              $ <span>{bst?.price}</span>{" "}
              {t("front_page.checkout_post_usd_txt")}
            </Col>
            <Col xs={12} md={8} className="ps-4 ps-md-5 note-desc mt-2">
              {bst?.description}
            </Col>
          </React.Fragment>
        ))}
        {pricingData?.total_monthly_price != 0 && (
          <>
            <hr className="mt-3"></hr>

            {/* post duration */}
            <Col xs={8} md={8} lg={7} className="ps-4 ps-md-5 cat-subcat-name">
              {t("front_page.checkout_post_post_duration_txt")}
            </Col>
            <Col
              xs={12}
              md={4}
              lg={5}
              className="ps-4 ps-md-0 mt-2 mt-md-0 d-flex cat-subcat-name justify-content-start gap-4 justify-content-md-end"
            >
              <span className="d-flex">
                <Form.Check
                  type="radio"
                  id={`duration-30`}
                  label="30 days"
                  name="post_duration"
                  value={30}
                  checked={selectedDuration == 30}
                  onChange={handleChange}
                  className="custom-radio" // Add custom class
                />
              </span>
              {pricingData?.total_yearly_price != null &&
                pricingData?.total_yearly_price != 0 && (
                  <span className="d-flex">
                    <Form.Check
                      type="radio"
                      id={`duration-365`}
                      label="1 year"
                      name="post_duration"
                      value={365} // Change this to 365 for 1 year
                      checked={selectedDuration == 365}
                      onChange={handleChange}
                      className="custom-radio" // Add custom class
                    />
                  </span>
                )}
            </Col>
          </>
        )}

        <hr className="mt-3"></hr>

        <Col
          xs={12}
          md={10}
          lg={9}
          className="ps-4 ps-md-5 total-amount text-end"
        >
          {t("front_page.checkout_total_amount_charges_txt")}:
        </Col>
        <Col xs={12} md={2} lg={3} className="total-amount text-end">
          $ <span>{totalAmount}.00</span>{" "}
          {t("front_page.checkout_post_usd_txt")}
        </Col>
      </Row>

      {/* Payment Details */}
      <Row className="details-row mt-5 mx-1">
        <Col xs={12} md={12}>
          {t("front_page.checkout_post_payment_details_txt")}
        </Col>
      </Row>
      {/* <Row className="d-flex ps-3 payment-add-card">
                
            </Row> */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="payment-add-card-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            {t("front_page.checkout_add_new_card_txt")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ cardOwner: "" }}
            validationSchema={validationSchema}
            onSubmit={handleAddCard}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="cardOwner" className="mt-3">
                  <Form.Label>
                    {t("front_page.checkout_card_owner_txt")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the name on the card"
                    value={values.cardOwner}
                    onChange={handleChange}
                    isInvalid={touched.cardOwner && !!errors.cardOwner}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.cardOwner)}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mt-4">
                  <CardElement
                    className="border p-2"
                    options={{ hidePostalCode: true }}
                  />
                </div>

                <Button type="submit" className="mt-5 w-100" disabled={!stripe}>
                  {addingCard ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    t("front_page.checkout_submit_btn_txt")
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Row className="details-data-row mt-2 mx-1">
        {/* post name */}
        <Col md={12} className="ps-5">
          <ListGroup className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="primary-card-title">
                {" "}
                {t("front_page.checkout_primary_card_txt")}
              </h5>
              <h6
                className="add-new-card-btn"
                onClick={() => setShowModal(true)}
              >
                + {t("front_page.checkout_add_new_card_txt")}
              </h6>
            </div>

            {cards.length === 0 ? (
              <div className="text-center mt-3">
                <p> {t("front_page.checkout_no_card_added_txt")}</p>
              </div>
            ) : (
              cards.map((card) => (
                <div className="my-2" key={card.id}>
                  <div className="radio-btn-payment">
                    <div className="d-flex align-items-center position-relative">
                      {/* Custom radio button */}
                      <span className="custom-radio-position">
                        <input
                          type="radio"
                          id={`card-${card.id}`}
                          name="cardSelection"
                          checked={selectedCardId === card.id}
                          onChange={() => handleCardSelect(card.id)}
                          className="custom-radio me-2"
                        />
                        <label
                          htmlFor={`card-${card.id}`}
                          className="custom-radio-label"
                        ></label>
                      </span>
                      <ListGroup.Item
                        className="d-flex justify-content-between align-items-center border w-100 rounded bg-theme text-light"
                        onClick={() => handleCardSelect(card.id)} // Clickable area for card
                        style={{ cursor: "pointer" }} // Change cursor to pointer
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <strong>{card.card.brand}</strong>
                            {"**********" + card.card.last4}
                          </div>
                        </div>
                        <Button
                          variant="danger"
                          className="remove-card"
                          onClick={() => handleDeleteCard(card.id)}
                        >
                          {deletingCardId === card.id ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                        </Button>
                      </ListGroup.Item>
                    </div>
                  </div>
                </div>
              ))
            )}

            <div className="d-flex justify-content-center mt-3">
              <Button
                className="w-100 p-3 rounded rounded-4 fw-bold"
                onClick={() => handlePayment(selectedCardId)}
              >
                {totalAmount != 0
                  ? t("front_page.checkout_pay_now_txt")
                  : t("front_page.checkout_confirm_txt")}
              </Button>
            </div>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

export default Checkout;
