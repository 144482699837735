import React from "react";

function LikeButton() {
  return (
    <>
      <svg
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ads-like-btn"
      >
        <g filter="url(#filter0_d_1580_947)">
          <rect x="12" y="11" width="40" height="40" rx="8" fill="#1C4532" />
        </g>
        <path
          d="M32 40.3753L30.4896 39.0212C28.7361 37.4413 27.2864 36.0784 26.1406 34.9326C24.9948 33.7868 24.0833 32.7581 23.4062 31.8467C22.7291 30.9352 22.2561 30.0975 21.987 29.3337C21.7179 28.5698 21.5833 27.7885 21.5833 26.9899C21.5833 25.358 22.1302 23.9951 23.2239 22.9014C24.3177 21.8076 25.6805 21.2607 27.3125 21.2607C28.2153 21.2607 29.0746 21.4517 29.8906 21.8337C30.7066 22.2156 31.4097 22.7538 32 23.4482C32.5903 22.7538 33.2934 22.2156 34.1094 21.8337C34.9253 21.4517 35.7847 21.2607 36.6875 21.2607C38.3194 21.2607 39.6823 21.8076 40.776 22.9014C41.8698 23.9951 42.4166 25.358 42.4166 26.9899C42.4166 27.7885 42.2821 28.5698 42.013 29.3337C41.7439 30.0975 41.2708 30.9352 40.5937 31.8467C39.9166 32.7581 39.0052 33.7868 37.8594 34.9326C36.7135 36.0784 35.2639 37.4413 33.5104 39.0212L32 40.3753ZM32 37.5628C33.6666 36.0698 35.0382 34.7894 36.1146 33.7217C37.1909 32.654 38.0416 31.7252 38.6666 30.9352C39.2916 30.1453 39.7257 29.4422 39.9687 28.8258C40.2118 28.2095 40.3333 27.5975 40.3333 26.9899C40.3333 25.9482 39.9861 25.0802 39.2916 24.3857C38.5972 23.6913 37.7291 23.3441 36.6875 23.3441C35.8715 23.3441 35.1163 23.5741 34.4219 24.0342C33.7274 24.4942 33.25 25.0802 32.9896 25.792H31.0104C30.75 25.0802 30.2725 24.4942 29.5781 24.0342C28.8837 23.5741 28.1285 23.3441 27.3125 23.3441C26.2708 23.3441 25.4028 23.6913 24.7083 24.3857C24.0139 25.0802 23.6666 25.9482 23.6666 26.9899C23.6666 27.5975 23.7882 28.2095 24.0312 28.8258C24.2743 29.4422 24.7083 30.1453 25.3333 30.9352C25.9583 31.7252 26.809 32.654 27.8854 33.7217C28.9618 34.7894 30.3333 36.0698 32 37.5628Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_1580_947"
            x="0"
            y="0"
            width="68"
            height="68"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="3" />
            <feGaussianBlur stdDeviation="7" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1580_947"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1580_947"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
}

export default LikeButton;
