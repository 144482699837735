import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required("front_page.business_name_required")
    .min(2, "front_page.business_name_max_char"),
  role: Yup.string()
    .required("front_page.business_role_required")
    .min(2, "front_page.business_role_max_char"),

  // businessType: Yup.number().required("Business Type is required"),

  location: Yup.string()
    .required("front_page.business_location_required")
    .min(2, "front_page.business_location_max_char"),

  webUrl: Yup.string()
    .url("front_page.business_website_invalid")
    .required("front_page.business_website_required"),
});

export default validationSchema;
