import React, { useEffect, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  useGetLastAds,
  useGetAllCategoryNameList,
  useListAds,
  useGetAdverstisementList,
} from "hooks";
import header_logo from "assets/images/header_logo.png";
import { useSearch } from "context/SearchContext";
import {
  HOME,
  ABOUT,
  CONTACT,
  FAQ,
  CMS_ROUTES,
  PRIVACY,
  TERMS,
  POST_ADVERTISEMENT,
  POST_LIST,
  ADS,
  CREATE_ADS,
  SELECT_CATEGORY,
  LOGIN,
} from "config";

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const cityData = useSelector((state) => state.cityState);

  const { setSearchTerm } = useSearch();
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const [search, setSearch] = useState(
    localStorage.searchItemPost
      ? JSON.parse(localStorage.searchItemPost).search
      : ""
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);
  const [previousAds, setPreviousAds] = useState([]);

  const { mutate: doGetLastAds, isLoading: gettingAds } = useGetLastAds(
    (res) => {
      if (Object.keys(res?.data).length > 0) {
        setPreviousAds([res?.data]);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { data: categories } = useGetAllCategoryNameList({
    search_name: search,
  });

  useEffect(() => {
    if (user?.user?.user_id != undefined) {
      doGetLastAds({ user_id: user?.user?.user_id });
    }
  }, [user, doGetLastAds]);

  useEffect(() => {
    const handleMediaChange = () => {
      const isMobile = window.matchMedia("(max-width: 575px)").matches;
      const isTablet = window.matchMedia(
        "(min-width: 576px) and (max-width: 991px)"
      ).matches;

      setIsMobile(isMobile);
      setCurrentView(isMobile ? "phone" : isTablet ? "tablet" : "desktop");
    };

    handleMediaChange();
    window.addEventListener("resize", handleMediaChange);

    return () => window.removeEventListener("resize", handleMediaChange);
  }, []);

  useEffect(() => {
    localStorage.setItem("searchItemPost", JSON.stringify({ search }));
    setSearchTerm(search);
  }, [search, setSearchTerm]);

  useEffect(() => {
    if (prevLocation && location.key === prevLocation.key) {
      setSearch("");
      setSearchTerm("");
    }
    setPrevLocation(location);
  }, [location, prevLocation, setSearchTerm]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const { refetch } = useGetAdverstisementList(
    {
      category_id: "",
      sub_category_id: "",
      state: "",
      city: "",
      searchText: search,
      sub_category_ids: [],
      checked_attribute_ids: [],
      sortOrder: "",
      page: "",
      limit: "",
    },
    (response) => {
      if (response?.data && response?.data?.ads?.length > 0) {
        const category = {
          ...JSON.parse(localStorage.getItem("category")),

          category_id:
            response.data.ads[0].advertisements_categories[0].sub_category_id,
          parent_id:
            response.data.ads[0].advertisements_categories[0].category_id,
        };
        localStorage.setItem("category", JSON.stringify(category));
        setSelectedCategory(
          response.data.ads[0].advertisements_categories[0].category_id
        );
      }
    }
  );
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      refetch();

      navigate(
        `/search${
          cityData?.state_name
            ? "/" + cityData.state_name.replace(/\s+/g, "-")
            : ""
        }${
          cityData?.name ? "/" + cityData.name.replace(/\s+/g, "-") : ""
        }/${selectedCategory.replace(/\s+/g, "-")}`,
        {
          state: { selectedCategory },
        }
      );
    }
  };

  const handleCreatePostBtn = async () => {
    localStorage.setItem("route", "create_post");
    if (user?.user?.user_id != undefined) {
      await doGetLastAds({ user_id: user?.user?.user_id });
      if (!gettingAds && previousAds.length > 0) {
        navigate(ADS + CREATE_ADS, {
          state: {
            subcategoryIds: previousAds[0]?.sub_category_id,
            parentCategory: previousAds[0]?.category_id,
            advertisementId: previousAds[0]?.advertisement_id,
            step: previousAds[0]?.step,
          },
        });
      } else {
        navigate(ADS + SELECT_CATEGORY);
      }
    } else {
      navigate(LOGIN);
    }
  };

  return (
    <div className="sidebar bg-theme text-white position-relative p-3">
      <button
        type="button"
        className="btn-close canvas-btn"
        aria-label="Close"
        onClick={() =>
          document.querySelector(".offcanvas")?.classList.remove("show")
        }
      ></button>
      <div className="sidebar-top mb-4">
        <h2 className="text-center mb-4">
          <Link
            to={HOME}
            className="text-white text-decoration-none"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            <img src={header_logo} alt="Header logo" className="home-logo" />
          </Link>
        </h2>
        <div className="post-add-btn mb-3">
          <Button
            variant="dark"
            className="mb-3 w-100 btn-add"
            onClick={handleCreatePostBtn}
          >
            <FontAwesomeIcon icon={faPenToSquare} className="me-1" />
            {t("front_page.sidebar_post_add_btn_txt")}
          </Button>
        </div>
        {currentView === "desktop" && (
          <div className="d-flex flex-column">
            <div className="input-group position-relative">
              <input
                type="text"
                id="search"
                name="search"
                placeholder={t("front_page.sidebar_search_placeholder")}
                value={search}
                className="form-control text-light"
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="search-icon position-absolute"
              />
            </div>
          </div>
        )}
      </div>

      <div className="sidebar-bottom">
        <Nav className="flex-column list-unstyled link-lists">
          <Link
            to={ABOUT}
            className="text-white nav-link-custom"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            {t("front_page.sidebar_link_about")}
          </Link>
          <Link
            to={CONTACT}
            className="text-white nav-link-custom"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            {t("front_page.sidebar_link_contact_us")}
          </Link>
          <Link
            to={FAQ}
            className="text-white nav-link-custom"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            {t("front_page.sidebar_link_help")}
          </Link>
          <Link
            to={CMS_ROUTES + PRIVACY}
            className="text-white nav-link-custom"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            {t("front_page.sidebar_link_privacy")}
          </Link>
          <Link
            to={CMS_ROUTES + TERMS}
            className="text-white nav-link-custom last-row"
            onClick={() =>
              document.querySelector(".offcanvas")?.classList.remove("show")
            }
          >
            {t("front_page.sidebar_link_term")}
          </Link>
        </Nav>
      </div>
    </div>
  );
};

export { Sidebar };
