import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { AdsService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAddAds = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.addAds, {
    onSuccess,
    onError,
  });
};

const useListAds = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_list", params],
    () => {
      return AdsService.listAds({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetVariations = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_variations", [params]],
    () => {
      return AdsService.getVariations({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetAdvertisement = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_advertisements", [params]],
    () => {
      return AdsService.getAdvertisement({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetStates = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_states"],
    () => {
      return AdsService.getSates();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCities = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_cities"],
    () => {
      return AdsService.getCities();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.statusChange, {
    onSuccess,
    onError,
  });
};
const useAdsStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.adsStatusChange, {
    onSuccess,
    onError,
  });
};
const useUpdateRenewStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.updateRenewStatus, {
    onSuccess,
    onError,
  });
};
const useGetAdvertisementById = (
  params,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ["ads_advertisement", [params]],
    () => {
      return AdsService.getAdvertisementByID({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPricing = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_pricing", [params]],
    () => {
      return AdsService.getPricing({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetFinalPrice = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_final_pricing", [params]],
    () => {
      return AdsService.getFinalPrice({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetReview = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_reviews", [params]],
    () => {
      return AdsService.getReviews({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useAddReview = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.addReviews, {
    onSuccess,
    onError,
  });
};
const useGetUserPostCount = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["post_counts", [params]],
    () => {
      return AdsService.getUserPostCount({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetUserPostList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["post_list", [params]],
    () => {
      return AdsService.getUserPostList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetRecommendedAds = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["get-recommended-ads", [params]],
    () => {
      return AdsService.getRecommendedAds({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetLastAds = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.getLastAds, {
    onSuccess,
    onError,
  });
};
const useClearDraft = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.clearDraft, {
    onSuccess,
    onError,
  });
};

const useStoreRatingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.storeRating, {
    onSuccess,
    onError,
  });
};

const useStoreReportData = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.storeReport, {
    onSuccess,
    onError,
  });
};

const useGetRatingList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["get-rating-list", [params]],
    () => {
      return AdsService.getRatingList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddToFavorite = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.addFavorite, {
    onSuccess,
    onError,
  });
};
const useGetFavorite = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["get-favorite-list", [params]],
    () => {
      return AdsService.getFavorite({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCompletedList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["get-completed-list", [params]],
    () => {
      return AdsService.getCompleted({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCancelledList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["get-cancelled-list", [params]],
    () => {
      return AdsService.getCancelled({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useStoreReplies = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.storeReplies, {
    onSuccess,
    onError,
  });
};
const useEditReplies = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdsService.editReplies, {
    onSuccess,
    onError,
  });
};

export {
  useAddAds,
  useListAds,
  useGetVariations,
  useGetAdvertisement,
  useGetStates,
  useGetCities,
  useStatusChange,
  useGetAdvertisementById,
  useAdsStatusChange,
  useUpdateRenewStatus,
  useGetPricing,
  useGetFinalPrice,
  useGetReview,
  useAddReview,
  useGetUserPostCount,
  useGetRecommendedAds,
  useGetLastAds,
  useGetUserPostList,
  useClearDraft,
  useStoreRatingData,
  useGetRatingList,
  useAddToFavorite,
  useGetFavorite,
  useGetCompletedList,
  useGetCancelledList,
  useStoreReportData,
  useStoreReplies,
  useEditReplies,
};
