import { DashboardService } from "api";
import { toast } from "react-toastify";

const { useQuery } = require("react-query");

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetAdsCounter = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["ads_count", params],
    () => {
      return DashboardService.getAdsCounter(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetAdsCounter };
