import client from "../libs/HttpClient";
class SettingService {
  static getSettingData(params) {
    return client.get("settings", { params });
  }
  static getTeamMemberData() {
    return client.get("team/get-team-member");
  }
  static getPostSettingData(params) {
    return client.get("settings/post-setting", { params });
  }
}
export { SettingService };
