import React from "react";

function LikeFillIcon() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 20.9994L10.55 19.6994C8.86667 18.1828 7.475 16.8744 6.375 15.7744C5.27499 14.6744 4.4 13.6869 3.75 12.812C3.09999 11.9369 2.64583 11.1327 2.38749 10.3994C2.12917 9.66608 2 8.91608 2 8.14941C2 6.58275 2.525 5.27441 3.575 4.22441C4.625 3.17441 5.93332 2.64941 7.5 2.64941C8.36666 2.64941 9.19166 2.83275 9.975 3.19941C10.7583 3.56608 11.4333 4.08274 12 4.74941C12.5666 4.08274 13.2416 3.56608 14.025 3.19941C14.8084 2.83275 15.6334 2.64941 16.5 2.64941C18.0666 2.64941 19.375 3.17441 20.425 4.22441C21.475 5.27441 22 6.58275 22 8.14941C22 8.91608 21.8708 9.66608 21.6125 10.3994C21.3541 11.1327 20.9 11.9369 20.25 12.812C19.6 13.6869 18.725 14.6744 17.625 15.7744C16.525 16.8744 15.1333 18.1828 13.45 19.6994L12 20.9994ZM12 17.5C12 16 12.4666 14.0249 13.5 13C14 11.5 12.9 11.7583 13.5 11C13.3133 9.91191 13.2666 10.0917 13.5 9.5C13.7333 8.90834 13.936 8.73274 13.936 8.14941C13.936 7.14941 13.936 7 14.1009 6.08164C13.4343 5.41497 14.325 4 15.3879 4.22441C14.6046 4.22441 14.9916 4.87024 14.325 5.31191C13.6583 5.75357 14.025 17.442 12.95 17.442H11.05C10.8 16.7587 8.7161 5.64941 10 6.5C9.33333 6.05833 11.7833 10 11 10C10 10 11.2612 11.5986 10.5945 12.2653C9.92785 12.9319 10.5 13.5 10.5 14.5C11 15 10.4889 14.72 10.5945 15.2956C10.8279 15.8873 10.83 15.7744 10.83 16.5C11.43 17.2583 11.6669 17.6615 11 17C12.0333 18.0249 12.5 15.5 12 17.5Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default LikeFillIcon;
