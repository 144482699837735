import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Container, Row, Col, Nav, Accordion } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import "assets/scss/page/_faq.scss";
import { useGetAllFaqData } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import favIcon from "../../assets/images/favicon.svg";
import { SeoHeader } from "common";

const StaticFaqs = {
  category_type: [
    {
      faq_category_id: "1",
      category_name: "Account",
      faq: [
        {
          faq_id: 1,
          question: "How to Change Account?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 2,
          question: "How to change password ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 3,
          question: "How to deactivate account ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 4,
          question: "How to change mobile number ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
      ],
    },
    {
      faq_category_id: "9",
      category_name: "career",
      faq: [
        {
          faq_id: 8,
          question:
            "This is to check career question. This is for testing Lorem ipsum dolor",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        },
      ],
    },
    {
      faq_category_id: "2",
      category_name: "Job",
      faq: [
        {
          faq_id: 9,
          question: "This is question",
          answer:
            "test answer Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        },
      ],
    },
  ],
};

const FaqPage = ({ getSettingData, t }) => {
  console.log("getSettingData: ", getSettingData);
  const [openKey, setOpenKey] = useState("0");
  const { data: getAllFaqData } = useGetAllFaqData(
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );

  // Extract category_type from the API response
  const faqData = getAllFaqData?.data || StaticFaqs;

  // Set the initial state of activeKey to the first category's faq_category_id
  const initialActiveKey =
    faqData?.category_type?.length > 0
      ? faqData?.category_type[0].faq_category_id
      : "0";
  const [activeKey, setActiveKey] = useState(initialActiveKey);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  useEffect(() => {
    if (faqData?.category_type?.length > 0) {
      setActiveKey(faqData?.category_type[0].faq_category_id.toString());
    }
  }, [faqData]);

  return (
    <>
      <SeoHeader
        title={getSettingData?.data?.faq_setting_faq_title + "| " + "Collegely"}
        description={getSettingData?.data?.faq_setting_faq_description}
        seoTitle={getSettingData?.data?.faq_setting_seo_title}
        seoDescription={getSettingData?.data?.faq_setting_seo_description}
        image={favIcon}
        favIcon={favIcon}
      />
      <Container
        fluid
        className="content-area common-spacing mb-5 faq-container"
      >
        <div className="mb-4">
          <h2 className="section-title d-flex flex-column">
            {getSettingData?.data.faq_setting_faq_title
              .split(" ")[0]
              ?.toUpperCase()}
          </h2>
          <span className="faq-description section-title">
            {getSettingData?.data.faq_setting_faq_title
              .split(" ")[1]
              ?.toUpperCase()}
          </span>
        </div>

        {faqData.category_type.length === 0 ? (
          <div className="no-data-message">
            <h2>No Categories Available</h2>
          </div>
        ) : (
          <div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="1"
              activeKey={activeKey}
              onSelect={handleSelect}
            >
              <Row>
                <Col sm={12} md={12}>
                  <Nav className="flex-lg-row  align-items-start faq-tab justify-content-start ">
                    {faqData?.category_type.map((category) => (
                      <Nav.Item key={category.faq_category_id} className="mb-2">
                        <Nav.Link
                          eventKey={category.faq_category_id}
                          className={`faq-link mx-2 ${
                            activeKey == category.faq_category_id
                              ? "tab-active-class"
                              : ""
                          }`}
                        >
                          {category.category_name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
              </Row>
              <Row className="mt-4  justify-content-start">
                <Col sm={12} md={12} xl={10} className="mt-xl-0">
                  <Tab.Content>
                    {faqData.category_type.length > 0 ? (
                      faqData?.category_type.map((category) => (
                        <Tab.Pane
                          key={category.faq_category_id}
                          eventKey={category.faq_category_id}
                        >
                          {category.faq.length === 0 ? (
                            <div className="no-data-message">
                              <h2>No FAQs Available for this Category</h2>
                            </div>
                          ) : (
                            <Accordion
                              defaultActiveKey={openKey}
                              activeKey={openKey}
                              onSelect={(k) => setOpenKey(k)}
                            >
                              {category.faq.map((faqItem) => (
                                <Accordion.Item
                                  key={faqItem.faq_id}
                                  eventKey={faqItem.faq_id.toString()}
                                  className="mt-3"
                                >
                                  <Accordion.Header className="acc-headers">
                                    <div className="d-flex justify-content-between w-100">
                                      <span>{faqItem.question}</span>
                                      <FontAwesomeIcon
                                        icon={
                                          openKey === faqItem.faq_id.toString()
                                            ? faMinus
                                            : faPlus
                                        }
                                        className={`ms-auto collepse-icon ${
                                          openKey === faqItem.faq_id.toString()
                                            ? "active"
                                            : ""
                                        }`}
                                        style={{ transition: "transform 0.3s" }}
                                      />
                                    </div>
                                  </Accordion.Header>

                                  <Accordion.Body>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: faqItem?.answer,
                                      }}
                                      className="dynamic-html-data"
                                    ></div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          )}
                        </Tab.Pane>
                      ))
                    ) : (
                      <h2>No Data Found</h2>
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        )}
      </Container>
    </>
  );
};

FaqPage.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      faq_setting_faq_title: PropTypes.string,
      faq_setting_faq_description: PropTypes.string,
    }),
  }),
};

export default FaqPage;
