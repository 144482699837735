import client from "../libs/HttpClient";
class ChatService {
  static listChatRooms(request) {
    return client.get("chat/chat-room-list", request);
  }
  static createChatRoom(request) {
    return client.post("chat/create-chat-room", request);
  }
  static listChatMessages({ params }) {
    return client.get("chat/get-messages", { params });
  }
  static sendMessage(request) {
    return client.post("chat/send-message", request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  static clearChat(request) {
    return client.post("chat/clear-chat", request);
  }
  static deleteChat(request) {
    return client.post("chat/delete-chat", request);
  }
  static markRead(request) {
    return client.post("chat/mark-as-read", request);
  }
  static deleteMsg(request) {
    return client.post("chat/delete-massage", request);
  }
  static reportMsg(request) {
    return client.post("report/store-report", request);
  }
  static unreadMsgs(request) {
    return client.post("chat/get-unread-massage", request);
  }
}
export { ChatService };
