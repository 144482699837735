import client from "../libs/HttpClient";
class HomeService {
  static getFeatureData() {
    return client.get("features/get-features");
  }

  static getDataByApplyFilter({ params }) {
    return client.get(`category/list?search_text_filter`, { params });
  }

  static getAllCategoryNameList({ params }) {
    return client.get("category/category-name-list", { params });
  }
}
export { HomeService };
