import { createSlice } from "@reduxjs/toolkit";

/**
 * Slice for Loader
 */
const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    count: 0,
    fcmToken: "",
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload?.notifications;
      state.count = action?.payload?.counts;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
      state.count += 1;
    },
    storeToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
});
const { setNotifications, addNotification, storeToken } =
  notificationSlice.actions;
export { notificationSlice, setNotifications, addNotification, storeToken };
export default notificationSlice.reducer;
