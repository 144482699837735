import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cardOwner: Yup.string()

    .required("front_page.card_owner_required")
    .min(2, "front_page.card_owner_min")
    .max(50, "front_page.card_owner_max"),
});

export default validationSchema;
