import { CategoryService } from "api";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListCategory = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["category_list", [params]],
    () => {
      return CategoryService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useListCategory };
