import React, { useState } from "react";
import { useSelector } from "react-redux";

import "assets/scss/page/_dashboard.scss";
import { useGetAdsCounter } from "hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ADS, LIST_ADS, COMPLETED, CANCELLED } from "config";
import { CommonHelmet } from "common";

const Dashboard = ({ getSettingData, t }) => {
  const [counter, setCounter] = useState();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const { refetch } = useGetAdsCounter(
    { user_id: user?.user_id },
    (response) => {
      if (response) {
        setCounter(response?.data);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  return (
    <>
      <CommonHelmet
        title={"front_page.dashboard_seo_title"}
        description={"front_page.dashboard_seo_title"}
      />
      <div className="dashboard p-3">
        <h2 className="mb-4">{t("front_page.dashboard_title")}</h2>
        <div className="dashboard-container">
          <div
            className="dashboard-block"
            onClick={() => {
              navigate(ADS + LIST_ADS);
            }}
          >
            <h2 className="block-title">{t("front_page.current_ads_tilte")}</h2>
            <p className="counter">{counter?.currentAdsCount}</p>
          </div>
          <div
            className="dashboard-block"
            onClick={() => {
              navigate(ADS + COMPLETED);
            }}
          >
            <h2 className="block-title">
              {t("front_page.complete_ads_tilte")}
            </h2>
            <p className="counter">{counter?.completedAdsCount}</p>
          </div>
          <div
            className="dashboard-block"
            onClick={() => {
              navigate(ADS + CANCELLED);
            }}
          >
            <h2 className="block-title">
              {t("front_page.cancelled_ads_tilte")}
            </h2>
            <p className="counter">{counter?.cancelledAdsCount}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
