import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { SettingService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ["setting_data", [params]],
    () => {
      return SettingService.getSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetAllTeamMemberData = (onSuccess, onError) => {
  return useQuery(
    "all_team_member_data",
    () => {
      return SettingService.getTeamMemberData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetPostSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ["setting_data", [params]],
    () => {
      return SettingService.getPostSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetSettingData, useGetAllTeamMemberData, useGetPostSettingData };
