import React from "react";

const copyLinkIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4383 11.0268C15.0834 10.6718 14.662 10.3902 14.1983 10.198C13.7345 10.0059 13.2374 9.90701 12.7354 9.90701C12.2335 9.90701 11.7364 10.0059 11.2726 10.198C10.8089 10.3902 10.3875 10.6718 10.0326 11.0268L6.1714 14.8892C5.45471 15.606 5.05214 16.5782 5.05225 17.5919C5.05235 18.6056 5.45513 19.5777 6.17197 20.2944C6.8888 21.0111 7.86098 21.4136 8.87464 21.4135C9.8883 21.4134 10.8604 21.0106 11.5771 20.2938L11.939 19.9511M11.5771 14.8892C11.932 15.2442 12.3533 15.5258 12.8171 15.718C13.2809 15.9101 13.7779 16.009 14.2799 16.009C14.7819 16.009 15.279 15.9101 15.7427 15.718C16.2065 15.5258 16.6279 15.2442 16.9828 14.8892L20.8428 11.0268C21.5597 10.3101 21.9625 9.33804 21.9626 8.32438C21.9627 7.31071 21.5601 6.33852 20.8434 5.62168C20.1267 4.90484 19.1546 4.50206 18.141 4.50195C17.1273 4.50185 16.1551 4.90442 15.4383 5.62112L14.2794 6.70452"
      stroke="black"
      stroke-width="2.25473"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default copyLinkIcon;
