import React, { useEffect, useRef, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import EmailIcon from "../../assets/svgs/EmailIcon";
import Facebook from "../../assets/svgs/FacebookIcon";
import Instagram from "../../assets/svgs/InstagramIcon";
import Copylink from "../../assets/svgs/copyLinkIcon";
import Linkedin from "../../assets/svgs/LinkedinIcon";
import CloseIcon from "../../assets/svgs/CloseIcon";
import "../../assets/scss/page/_user_profile.scss";

const ShareReport = ({ t, url, setIsPopoverOpen, userEmail }) => {
  const [copied, setCopied] = useState(false);
  const popoverRef = useRef(null);
  const shareUrl = url;
  const handleCopyLink = () => {
    let linkToCopy = shareUrl;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const handleShare = (platform) => {
    const encodeUrl = encodeURIComponent(shareUrl);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const subject = "Collegely";
    switch (platform) {
      case "email":
        url = `https://mail.google.com/mail/?view=cm&fs=1&to=${userEmail}&su=${encodeURIComponent(subject)}&body=${encodeUrl}`;
        break;
      case "facebook":
        url = `https://m.me/?message=${encodeUrl}`;
        break;
      case "instagram":
        if (isMobile) {
          url = `instagram://direct/new?text=${encodeUrl}`;
        } else {
          url = `https://www.instagram.com/`;
        }
        break;
      case "linkedin":
        url = `https://www.linkedin.com/messaging/compose/?body=${encodeUrl}`;
        break;
      default:
        return;
    }

    // Open the share link in a new window
    window.open(url, "_blank", "width=600,height=400");
  };

  return (
    <Popover.Body>
      <div className="main-block">
        <div className="modal-item">
          <span onClick={() => handleShare("email")}>
            <EmailIcon />
            {t("front_page.email_text")}
          </span>
        </div>
        <div className="modal-item" onClick={() => handleShare("facebook")}>
          <Facebook />
          {t("front_page.facebook_text")}
        </div>
        <div className="modal-item" onClick={() => handleShare("instagram")}>
          <Instagram />
          {t("front_page.instagram_text")}
        </div>
        <div className="modal-item" onClick={() => handleShare("linkedin")}>
          <Linkedin />
          {t("front_page.linkedin_text")}
        </div>
        <div
          className="modal-item"
          style={{ border: "none", marginBottom: "-8px" }}
          onClick={handleCopyLink}
        >
          <Copylink />
          {copied
            ? t("front_page.coppied_text")
            : t("front_page.copy_link_text")}
        </div>
      </div>
    </Popover.Body>
  );
};

export default ShareReport;
