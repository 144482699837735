import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useResetPassword } from "hooks";
import validationSchema from "./ResetPasswordValidation";
import { setToken } from "store/features/authSlice";
import { LOGIN } from "config";
import { HOME } from "config";
import { CommonHelmet } from "common";

const ResetPasswordPage = ({ t }) => {
  const forgot_token = useSelector((state) => state.user.forgot_token);
  const initialValues = {
    reset_token: forgot_token,
    password: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: doResetPassword, isLoading } = useResetPassword((res) => {
    if (res.data) {
      dispatch(setToken({ forgot_token: "" }));
      toast.success(res.message);
      navigate(LOGIN);
    }
  });

  return (
    <>
      <CommonHelmet
        title={"front_page.reset_password_seo_title"}
        description={"front_page.reset_password_seo_title"}
      />
      <Container className="d-flex justify-content-center align-items-center mt-4 container">
        <div className="forgot-password-content">
          <Link to={HOME} className="pointer-event">
            <img src={header_logo} alt="Home logo" className="home-logo" />
          </Link>
          <div className="my-3">
            <h3>{t("front_page.reset_password_set_new_title")}</h3>
            <p>{t("front_page.reset_password_sub_title")}</p>
          </div>

          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                doResetPassword({
                  reset_token: forgot_token,
                  password: values.password,
                });
                //   navigate("/otp-verification");
              }}
            >
              {({ errors, touched }) => (
                <FormikForm className="login-form">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("front_page.reset_password_new_password_label")}
                    </Form.Label>
                    <InputGroup>
                      <Field
                        as={FormControl}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your password"
                        className="login-password w-100"
                        autoComplete="off"
                        isInvalid={!!errors.password && touched.password}
                      />
                      <span className="password-icon">
                        <InputGroup.Text
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Text>
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {t(errors.password)}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("front_page.reset_password_confirm_password_label")}
                    </Form.Label>
                    <InputGroup>
                      <Field
                        as={FormControl}
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        className="login-password"
                        autoComplete="off"
                        isInvalid={
                          !!errors.confirmPassword && touched.confirmPassword
                        }
                      />
                      <span className="password-icon">
                        <InputGroup.Text
                          onClick={togglePasswordVisibilityConfirm}
                          style={{ cursor: "pointer" }}
                        >
                          {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Text>
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {t(errors.confirmPassword)}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 my-3 register-btn"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {t("front_page.reset_password_new_password_loading")}
                      </>
                    ) : (
                      t("front_page.reset_password_new_password_confirm")
                    )}
                  </Button>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ResetPasswordPage;
