import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\s)$/,
      "front_page.validation_email_invalid",
    )
    .email("front_page.validation_email_invalid")
    .required("front_page.validation_email_required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "front_page.validation_password_complexity",
    )
    .required("front_page.validation_password_required"),
});

export default validationSchema;
