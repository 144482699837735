import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { HomeService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetAllFeatureData = (onSuccess, onError) => {
  return useQuery(
    "all_feature_data",
    () => {
      return HomeService.getFeatureData();
    },
    {
      onSuccess,
      onError,
    },
  );
};

const useGetAllDataBySearchFilter = (
  params,
  onSuccess,
  onError = onDefaultError,
) => {
  return useQuery(
    "home-filter-list",
    () => {
      return HomeService.getDataByApplyFilter({ params });
    },
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    },
  );
};

const useGetAllCategoryNameList = (
  params,
  onSuccess,
  onError = onDefaultError,
) => {
  return useQuery(
    "category-name-list",
    () => {
      return HomeService.getAllCategoryNameList({ params });
    },
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    },
  );
};

export {
  useGetAllFeatureData,
  useGetAllDataBySearchFilter,
  useGetAllCategoryNameList,
};
