import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { ABOUT } from "config";
import { CONTACT } from "config";
import { FAQ } from "config";
import { CMS_ROUTES } from "config";
import { PRIVACY } from "config";
import { TERMS } from "config";

function Footer({ getSettingData, t }) {
  return (
    <footer className="text-white ">
      <Container fluid className="p-3">
        <div className="me-3 d-flex justify-content-center align-items-center footer-content">
          <p className="mb-0 copy-right-text">
            {getSettingData?.data.home_setting_footer_copyright_txt}
          </p>

          <ul className="list-unstyled d-flex flex-wrap justify-content-center mb-0 link-list">
            <Link
              to={
                getSettingData?.data.home_setting_footer_about_us_link || ABOUT
              }
              className="text-white text-decoration-none mx-3"
            >
              {getSettingData?.data.home_setting_footer_about_us || "About"}
            </Link>
            <Link
              to={
                getSettingData?.data.home_setting_footer_contact_us_link ||
                CONTACT
              }
              className="text-white text-decoration-none mx-3"
            >
              {getSettingData?.data.home_setting_footer_contact_us ||
                "Contact Us"}
            </Link>
            <Link
              to={getSettingData?.data.home_setting_footer_help_link || FAQ}
              className="text-white text-decoration-none mx-3"
            >
              {getSettingData?.data.home_setting_footer_help || "Help"}
            </Link>
            <Link
              to={
                getSettingData?.data.home_setting_footer_privacy_link ||
                CMS_ROUTES + PRIVACY
              }
              className="text-white text-decoration-none mx-3"
            >
              {getSettingData?.data.home_setting_footer_privacy || "Privacy"}
            </Link>
            <Link
              to={
                getSettingData?.data.home_setting_footer_term_condition_link ||
                CMS_ROUTES + TERMS
              }
              className="text-white text-decoration-none mx-3"
            >
              {getSettingData?.data.home_setting_footer_term_condition ||
                "Term"}
            </Link>
          </ul>
          <div className="dbt-container d-none ms-2">
            <span class="dbt">
              DEVELOPED BY&nbsp;
              <a
                href="https://www.technource.com/"
                target="_blank"
                class="tooltiplink"
                rel="noopener noreferrer"
                data-title="Web App | Mobile App | Custom Software | AI Development"
              >
                TECHNOURCE
              </a>
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
}

// PropTypes validation
Footer.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default Footer;
