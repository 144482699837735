import FullScreenHeader from "common/layouts/Header/FullScreenHeader";
import FullScreenLeftMenu from "common/layouts/Sidebar/FullScreenLeftMenu";
import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import ViewAdsTabs from "./ViewAdsTabs";

function ViewAds({ getSettingData, children, t }) {
  const [sharedVariable, setSharedVariable] = useState("");
  return (
    <>
      <Container fluid>
        <Row>
          <FullScreenHeader></FullScreenHeader>
        </Row>
        <Row className="d-flex flex-nowrap">
          <FullScreenLeftMenu
            sharedVariable={sharedVariable}
            setSharedVariable={setSharedVariable}
          />
          <ViewAdsTabs setSharedVariable={setSharedVariable} />
        </Row>
      </Container>
    </>
  );
}

export default ViewAds;
