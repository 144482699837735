import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("front_page.forgot_password_invalid_email")
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "front_page.forgot_password_invalid_email",
    )
    .required("front_page.forgot_password_email_required"),
});

export default validationSchema;
