import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import PrivateHeader from "../Header/PrivateHeader";
import PrivateSidebar from "../Sidebar/PrivateSidebar";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";

const PrivateLayout = ({ getSettingData, children, t, socket }) => {
  // const { t } = useTranslation();
  const location = useLocation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [currentView, setCurrentView] = useState("desktop");
  const sidebarRef = useRef(null);
  const currentPage = location.pathname;

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarVisible(false);
    }
  };
  const footerShowsPath = ["/dashboard"];
  const isMatchingPath = (pattern, path) => {
    const regex = new RegExp(`^${pattern.replace("*", ".*")}$`);
    return regex.test(path);
  };
  const showFooter = footerShowsPath.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 992px)");
    const handleMediaChange = (e) => {
      setCurrentView(e.matches ? "phone" : "desktop");
    };

    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);

    // Event listener for clicks outside the sidebar
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      mediaQuery.removeListener(handleMediaChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const is_chat = window.location.href.split("/").pop() == "chat" || null;

  return (
    <>
      <div className={`${currentView == "desktop" ? "super-container" : ""}`}>
        <div
          className={
            "main-content-private-dashboard"
            //  "main-content-private"
          }
        >
          <div
            className={`d-flex flex-column flex-grow-1 bg-theme ${
              currentView == "desktop"
                ? "private-container w-100"
                : "main-content-private-custom"
            }`}
          >
            <PrivateHeader
              toggleSidebar={toggleSidebar}
              getSettingData={getSettingData}
              t={t}
              socket={socket}
            />
            <div
              className={`flex-grow-1 text-light ${
                currentView === "desktop"
                  ? `${is_chat ? "" : "centered-content mt-3"}`
                  : `${is_chat ? "" : "custom-centered-content mt-3"}`
              }`}
            >
              {children}

              <div ref={sidebarRef}>
                <PrivateSidebar
                  isOpen={isSidebarVisible}
                  toggleSidebar={toggleSidebar}
                  getSettingData={getSettingData}
                  t={t}
                  socket={socket}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer getSettingData={getSettingData} t={t} />
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout };
