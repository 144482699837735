import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cardOwner: Yup.string()

    .required("front_page.checkout_card_owner_name_required")
    .min(2, "front_page.checkout_card_owner_name_min")
    .max(50, "front_page.checkout_card_owner_name_max"),
});

export default validationSchema;
