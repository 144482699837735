import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./features/authSlice";
import loaderReducer from "./features/loaderSlice";
import settingReducer from "./features/settingSlice";
import notificationReducer from "./features/notificationSlice";
import advertisementReducer from "./features/advertisementSlice";
import cityStateReducer from "./features/cityStateSlice";

const persistConfig = {
  key: "collegely-admin",
  storage,
  blacklist: [],
};

const rootReducer = combineReducers({
  user: authReducer,
  loader: loaderReducer,
  setting: settingReducer,
  notifications: notificationReducer,
  advertisement: advertisementReducer,
  cityState: cityStateReducer,
  // Add other reducers here...
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

let persistor = persistStore(store);
export { persistor, store };
export {
  loginSuccess,
  logoutSuccess,
  updateUserData,
  updateAdvertisementData,
  updateCurrentStepperStep,
  setToken,
  loggedUser,
  showLoader,
  hideLoader,
  addSetting,
  clearSetting,
  notificationSlice,
  setNotifications,
  addNotification,
  settingData,
  loader,
  storeToken,
  updateUserLocation,
  addData,
  updateData,
} from "./features";
