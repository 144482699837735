import { commonOption, paymentStatusOptions } from "./options";
import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP_VERIFICATION,
  HOME,
  ABOUT,
  CONTACT,
  FAQ,
  POST_ADVERTISEMENT,
  ABCD,
  USER_PROFILE_VIEW,
  CMS_ROUTES,
  DASHBOARD,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  DEACTIVATE_ACCOUNT,
  MANAGE_CARD,
  BECOME_BUSINESS_USER,
  EDIT_BECOME_BUSINESS_USER,
  PAYMENT_HISTORY,
  ADS,
  NOTIFICATIONS,
  CHAT,
  VIEW_ADS,
  CREATE_ADS,
  CHECKOUT,
  SELECT_CATEGORY,
  SELECT_SUB_CATEGORY,
  PREVIEW,
  LIST_ADS,
  EDIT_ADS,
  COMPLETED,
  CANCELLED,
  FAVORITES,
  POST_LIST,
  PRIVACY,
  TERMS,
} from "./routes";
export {
  commonOption,
  paymentStatusOptions,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP_VERIFICATION,
  HOME,
  ABOUT,
  CONTACT,
  FAQ,
  POST_ADVERTISEMENT,
  ABCD,
  USER_PROFILE_VIEW,
  CMS_ROUTES,
  DASHBOARD,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  DEACTIVATE_ACCOUNT,
  MANAGE_CARD,
  BECOME_BUSINESS_USER,
  EDIT_BECOME_BUSINESS_USER,
  PAYMENT_HISTORY,
  ADS,
  NOTIFICATIONS,
  CHAT,
  VIEW_ADS,
  CREATE_ADS,
  CHECKOUT,
  SELECT_CATEGORY,
  SELECT_SUB_CATEGORY,
  PREVIEW,
  LIST_ADS,
  EDIT_ADS,
  COMPLETED,
  CANCELLED,
  FAVORITES,
  POST_LIST,
  PRIVACY,
  TERMS,
};

export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
