import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SeoHeader = ({
  title,
  description,
  seoTitle,
  seoDescription,
  image,
  favIcon,
}) => {
  return (
    <Helmet
      meta={[
        {
          name: "title",
          content: title,
        },
        {
          property: "ob:title",
          content: seoTitle,
        },
        {
          name: "description",
          content: description,
        },
        {
          property: "og:description",
          content: seoDescription,
        },
        {
          property: "og:image",
          content: image,
        },
      ]}
    >
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href={favIcon} />
    </Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
