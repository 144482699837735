import React from "react";

function ChatIcon() {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.68056 23.5826C9.32246 23.5826 9.02228 23.4615 8.78004 23.2192C8.53779 22.977 8.41667 22.6768 8.41667 22.3187V19.7909H24.8472V8.41591H27.375C27.7331 8.41591 28.0333 8.53703 28.2755 8.77928C28.5178 9.02152 28.6389 9.32169 28.6389 9.6798V28.6381L23.5833 23.5826H9.68056ZM3.36111 22.3187V4.62424C3.36111 4.26614 3.48224 3.96597 3.72448 3.72372C3.96673 3.48147 4.2669 3.36035 4.625 3.36035H21.0556C21.4137 3.36035 21.7138 3.48147 21.9561 3.72372C22.1983 3.96597 22.3195 4.26614 22.3195 4.62424V15.9992C22.3195 16.3573 22.1983 16.6575 21.9561 16.8998C21.7138 17.142 21.4137 17.2631 21.0556 17.2631H8.41667L3.36111 22.3187ZM19.7917 14.7354V5.88813H5.88889V14.7354H19.7917Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default ChatIcon;
