import * as Yup from "yup";

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "front_page.reset_password_password_match"
    )
    .required("front_page.reset_password_password_required"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "front_page.reset_password_confirm_password_match"
    )
    .required("front_page.reset_password_confirm_password_required"),
});

export default validationSchema;
