import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { NotificationsService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetNotifications = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    "notifications_data",
    () => {
      return NotificationsService.getNotificationsData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useDeleteNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.deleteNotification, {
    onSuccess,
    onError,
  });
};
const useReadAllNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.readAllNotifications, {
    onSuccess,
    onError,
  });
};

export { useGetNotifications, useDeleteNotification, useReadAllNotification };
