import React, { useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";

import "assets/scss/page/_auth.scss";
import { CommonHelmet, NavItemsCommon } from "common/components";
import { EditEmailNavItems } from "config/navConfig";
import { useChangePassword } from "hooks";
import validationSchema from "./ChangePasswordValidation";
const initialValues = {
  new_password: "",
};

const ChangePassword = ({ t }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: doChangePassword, isLoading } = useChangePassword((res) => {
    if (res.data) {
      toast.success(res.message);
    }
  });

  return (
    <>
      <CommonHelmet
        title={"front_page.change_password_seo_title"}
        description={"front_page.change_password_seo_title"}
      />
      <div>
        <h2>{t("front_page.change_pass_my_profile_label")}</h2>
        <NavItemsCommon navItemName={EditEmailNavItems} t={t} />
        <div className="d-flex justify-content-center p-4 p-md-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              doChangePassword(values);
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form change-pass-form">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.change_pass_new_password_label")}
                  </Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="new_password"
                      placeholder={t(
                        "front_page.change_pass_new_password_placeholder"
                      )}
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.new_password && touched.new_password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {t(errors.new_password)}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.change_pass_confirm_password_label")}
                  </Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder={t(
                        "front_page.change_pass_confirm_password_placeholder"
                      )}
                      className="login-password"
                      autoComplete="off"
                      isInvalid={
                        !!errors.confirmPassword && touched.confirmPassword
                      }
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibilityConfirm}
                        style={{ cursor: "pointer" }}
                      >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {t(errors.confirmPassword)}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 change-pass-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      {t("front_page.change_pass_loading_spinner_label")}
                    </>
                  ) : (
                    `${t("front_page.change_pass_confirm_btn_label")}`
                  )}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
