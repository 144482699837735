import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken } from "libs/HttpClient";

const initialState = {
  user: "",
  isUserLogin: false,
  accessToken: "",
  encoded_token: "",
  forgot_token: "",
  location: { city_id: "", name: "", state_id: "" },
  pathname: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.encoded_token = payload.encoded_token ? payload.encoded_token : "";
      state.forgot_token = payload.forgot_token ? payload.forgot_token : "";
    },

    loginSuccess: (state, { payload }) => {
      state.user = payload.userData;
      state.isUserLogin = true;
      state.accessToken = payload.accessToken;
      setAuthToken(payload.accessToken);
      return state;
    },
    updateUserData: (state, { payload }) => {
      state.user = payload.userData;
      return state;
    },
    updateUserLocation: (state, action) => {
      state.location = action.payload;
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
const {
  loginSuccess,
  logoutSuccess,
  updateUserData,
  updateUserLocation,
  setToken,
} = authSlice.actions;
export const loggedUser = (state) => state.user;
export {
  loginSuccess,
  logoutSuccess,
  updateUserData,
  setToken,
  updateUserLocation,
};
export default authSlice.reducer;
