import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import header_logo from "assets/images/header_logo.png";
import { useOtpVerification, useResendOtp } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "store/features/authSlice";
import { RESET_PASSWORD } from "config";
import { LOGIN } from "config";
import { HOME } from "config";
import CommonHelmet from "common/components/CommonHelmet";

const OTPVerification = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpDisabled, setOtpDisabled] = useState(false);

  const handleChange = (element, index) => {
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
  const forgot_token = useSelector((state) => state.user.forgot_token);
  const verifyToken = useSelector((state) => state.user.encoded_token);

  const handleVerify = () => {
    const values = {
      verification_otp: otp.join(""),
      encoded_token: forgot_token != "" ? forgot_token : verifyToken,
    };

    setOtp(["", "", "", "", "", ""]);
    doVerifyOTP(values);
  };

  const { mutate: doVerifyOTP, isLoading: isVerifying } = useOtpVerification(
    (res) => {
      if (res.data) {
        if (forgot_token != "") {
          navigate(RESET_PASSWORD);
        } else {
          dispatch(setToken({ encoded_token: "" }));
          navigate(LOGIN);
          toast.success(res.message);
        }
      }
    }
  );

  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""]);
    doResendOTP({
      encoded_token: forgot_token != "" ? forgot_token : verifyToken,
    });
    setOtpDisabled(true);

    setTimeout(() => {
      setOtpDisabled(false);
    }, 60000);
  };

  const { mutate: doResendOTP, isLoading: isSending } = useResendOtp((res) => {
    if (res.data) {
      toast.success("OTP resent successfully. Please check your email.");
    }
  });
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      if (index >= 0) {
        let newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        if (index > 0) {
          e.target.previousSibling.focus();
        }
      }
    }
  };
  return (
    <>
      <CommonHelmet
        title={"front_page.otp_verification_seo_title"}
        description={"front_page.otp_verification_seo_title"}
      />
      <Container className="d-flex justify-content-center align-items-center mt-5 login-container">
        <div className="otp-content">
          <div className="text-center p-4 bg-theme text-white rounded otp-center-div">
            <div className="text-start">
              <Link to={HOME} className="pointer-event">
                <img src={header_logo} alt="Home logo" className="home-logo" />
              </Link>

              <h2 className="mt-4">{t("front_page.otp_verification_title")}</h2>
              <p>{t("front_page.otp_verification_sub_title")}</p>
            </div>
            <Form>
              <div className="text-start my-2">
                <h5>{t("front_page.otp_verification_enter_otp")}</h5>
              </div>
              <div className="otp-input">
                {otp.map((_, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className="mx-1 text-center"
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
              <Button
                onClick={handleVerify}
                disabled={isVerifying}
                className="register-btn fw-bold"
              >
                {isVerifying ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    {t("front_page.otp_verification_verifying")}
                  </>
                ) : (
                  t("front_page.otp_verification_verify")
                )}
              </Button>
            </Form>
            <p className="mt-3">
              {t("front_page.otp_verification_do_not_receive")}{" "}
              <span
                className="text-decoration-underline hover-txt"
                style={{
                  cursor: otpDisabled ? "not-allowed" : "pointer",
                  color: otpDisabled ? "#aaa" : "#094936",
                }}
                onClick={otpDisabled ? null : handleResendOtp}
              >
                {otpDisabled
                  ? t("front_page.otp_verification_sent_txt")
                  : t("front_page.otp_verification_re_send_txt")}
              </span>
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OTPVerification;
