import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertisementData: {},
  currentStepperStep: null,
};

const advertisementSlice = createSlice({
  name: "advertisements",
  initialState,
  reducers: {
    updateAdvertisementData: (state, action) => {
      state.advertisementData = action.payload;
    },
    updateCurrentStepperStep: (state, action) => {
      state.currentStepperStep = action.payload;
    },
  },
});

export const { updateAdvertisementData, updateCurrentStepperStep } =
  advertisementSlice.actions;

export const selectAdvertisementState = (state) => state.advertisements;

export default advertisementSlice.reducer;
