import React from "react";

function UserProfilePhoneIcon() {
  return (
    <>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.2625 29.75C25.3111 29.75 22.3951 29.1066 19.5146 27.8198C16.634 26.533 14.0132 24.709 11.6521 22.3479C9.29097 19.9868 7.46701 17.366 6.18021 14.4854C4.8934 11.6049 4.25 8.68889 4.25 5.7375C4.25 5.3125 4.39167 4.95833 4.675 4.675C4.95833 4.39167 5.3125 4.25 5.7375 4.25H11.475C11.8056 4.25 12.1007 4.36215 12.3604 4.58646C12.6201 4.81076 12.7736 5.07639 12.8208 5.38333L13.7417 10.3417C13.7889 10.7194 13.7771 11.0382 13.7063 11.2979C13.6354 11.5576 13.5056 11.7819 13.3167 11.9708L9.88125 15.4417C10.3535 16.3153 10.9142 17.1594 11.5635 17.974C12.2128 18.7885 12.9271 19.5736 13.7063 20.3292C14.4382 21.0611 15.2056 21.7399 16.0083 22.3656C16.8111 22.9913 17.6611 23.5639 18.5583 24.0833L21.8875 20.7542C22.1 20.5417 22.3774 20.3823 22.7198 20.276C23.0622 20.1698 23.3986 20.1403 23.7292 20.1875L28.6167 21.1792C28.9472 21.2736 29.2188 21.4448 29.4312 21.6927C29.6437 21.9406 29.75 22.2181 29.75 22.525V28.2625C29.75 28.6875 29.6083 29.0417 29.325 29.325C29.0417 29.6083 28.6875 29.75 28.2625 29.75ZM8.53542 12.75L10.8729 10.4125L10.2708 7.08333H7.11875C7.23681 8.05139 7.40208 9.00764 7.61458 9.95208C7.82708 10.8965 8.13403 11.8292 8.53542 12.75ZM21.2146 25.4292C22.1354 25.8306 23.074 26.1493 24.0302 26.3854C24.9865 26.6215 25.9486 26.775 26.9167 26.8458V23.7292L23.5875 23.0562L21.2146 25.4292Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default UserProfilePhoneIcon;
