import * as Yup from "yup";
import "helpers/customValidation";

const validationSchema = Yup.object({
  full_name: Yup.string()
    .required("front_page.edit_profile_full_name_required")
    .max(100, "front_page.validation_name_max"),

  email: Yup.string()
    .email("front_page.edit_profile_email_invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\s)$/,
      "front_page.validation_email_invalid",
    )
    .required("front_page.edit_profile_email_required"),
});

const validationSchemaEmail = Yup.object({
  newEmail: Yup.string()
    .email("front_page.edit_profile_email_invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\s)$/,
      "front_page.validation_email_invalid",
    )
    .required("front_page.edit_profile_email_required"),
});

const validationSchemaVerify = Yup.object({
  verification_otp: Yup.string()
    .required("front_page.edit_profile_otp_required")
    .matches(/^[0-9\s]+$/, "front_page.edit_profile_otp_pattern"),
});

const validationSchemaPhone = Yup.object({
  newMobile: Yup.string()
    .phoneCheck("front_page.edit_profile_phone_invalid")
    .required("front_page.edit_profile_phone_required"),
});

export {
  validationSchema,
  validationSchemaEmail,
  validationSchemaVerify,
  validationSchemaPhone,
};
