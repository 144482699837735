import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "assets/scss/page/_ads.scss";

// Fix marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const greenIcon = new L.Icon({
  iconUrl: require("../../assets/images/map_pin.png"),
  iconRetinaUrl: require("../../assets/images/map_pin_2x.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
const AddMarkerOnClick = ({ setMarker, setMapLocation, setIsZoomSet }) => {
  useMapEvents({
    click(event) {
      const { lat, lng } = event?.latlng;

      // Only update the marker if it's a new location (avoid multiple pins)
      if (!setMarker) return;

      setMarker({ lat, lng });
      setMapLocation({ lat, lng });
      setIsZoomSet(false);
    },
  });
  return null;
};

const OpenStreetMapComponent = ({
  setMapLocation,
  initialCoordinates,
  cityCoordinates,
  cityName,
  isView,
  isZoomSet,
  setIsZoomSet,
}) => {
  const initialLatLong =
    initialCoordinates?.lat !== undefined &&
    initialCoordinates?.lng !== undefined
      ? initialCoordinates
      : null;

  const [marker, setMarker] = useState(initialLatLong || cityCoordinates);
  const [center, setCenter] = useState([0, 0]);
  const prevInitialLatLong = useRef();

  useEffect(() => {
    const newCenter = initialLatLong || cityCoordinates;
    if (newCenter != null) {
      const prevLatLong = prevInitialLatLong?.current;
      if (
        !prevLatLong ||
        newCenter?.lat != prevLatLong?.lat ||
        newCenter?.lng != prevLatLong?.lng
      ) {
        setMarker(newCenter);
        setMapLocation(newCenter);
        setCenter([newCenter?.lat, newCenter?.lng]);

        prevInitialLatLong.current = newCenter;
      }
    }
  }, [initialLatLong, cityCoordinates]);

  const ZoomToCity = ({ cityCoordinates }) => {
    const map = useMap();
    useEffect(() => {
      if (isZoomSet && cityCoordinates?.lat !== undefined) {
        setMarker(cityCoordinates);
        setMapLocation(cityCoordinates);
        map?.setView(cityCoordinates, 12);
      }
    }, [cityCoordinates, map, isZoomSet]);

    return null;
  };

  const handleMarkerDragEnd = (event) => {
    const newPosition = event.target.getLatLng();
    setIsZoomSet(false);
    setMarker({ lat: newPosition.lat, lng: newPosition.lng });
    setMapLocation({ lat: newPosition.lat, lng: newPosition.lng });
  };

  return center[0] == 0 ? null : (
    <MapContainer
      center={[center[0], center[1]]}
      zoom={12}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; collegely - Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      {!isView && (
        <AddMarkerOnClick
          setMarker={setMarker} // passing down setMarker to handle the click
          setMapLocation={setMapLocation}
          setIsZoomSet={setIsZoomSet}
        />
      )}

      {/* Display the marker if it's defined */}
      {marker && (
        <Marker
          position={[marker?.lat, marker?.lng]}
          icon={greenIcon}
          draggable={!isView} // Draggable only if isView is false
          eventHandlers={{
            dragend: handleMarkerDragEnd, // Update marker position when drag ends
          }}
        >
          {!isView && (
            <Popup>
              Your post will appear on maps. Drag pin or click map to choose
              exact location.
            </Popup>
          )}
        </Marker>
      )}

      {/* Zoom to the city if cityCoordinates are provided */}
      {cityCoordinates && <ZoomToCity cityCoordinates={cityCoordinates} />}
    </MapContainer>
  );
};

export default OpenStreetMapComponent;
