import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  city_id: null,
  name: null,
  state_id: null,
  state_name: null,
};
export const cityStateSlice = createSlice({
  name: "cityState",
  initialState: initialState,
  reducers: {
    addData: (state, { payload }) => {
      state.city_id = payload.city_id;
      state.name = payload.name;
      state.state_id = payload.state_id;
      state.state_name = payload.state_name;
      return state;
    },
    updateData: (state, { payload }) => {
      state.city_id = payload.city_id;
      state.name = payload.name;
      state.state_id = payload.state_id;
      state.state_name = payload.state_name;
      return state;
    },
  },
});
export const { addData, updateData } = cityStateSlice.actions;
export const cityStateData = (state) => state.cityState;
export default cityStateSlice.reducer;
