import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Row,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavItemsCommon } from "common/components/NavItemCommon";
import { EditEmailNavItems } from "config/navConfig";
import { FaEdit } from "react-icons/fa";
import {
  useGetCountryCodeList,
  useGetProfile,
  useUpdateProfile,
  useUpdateEmail,
  useUpdateEmailVerify,
  useSendOtpForMobile,
  useResendEmailOtp,
} from "hooks";
import { useDispatch, useSelector } from "react-redux";
import ProfileImage from "assets/images/team1.png";
import { updateUserData } from "store/features/authSlice";
import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  validationSchemaEmail,
  validationSchema,
  validationSchemaPhone,
} from "./EditProfileValidation";
import default_img from "assets/images/account_circle.svg";
import { CommonHelmet } from "common";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent", // Dark background
    borderColor: "#4D4D4D70", // Dark border
    borderWidth: "1px",
    boxShadow: "none", // Remove shadow
    color: "#fff", // White text
    "&:hover": {
      borderColor: "#2e2d2d", // Lighter border on hover
      color: "#fff", // White text
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "transparent", // Dark background for menu
    color: "#fff", // White text
    // Hide scrollbar
    overflowY: "hidden", // Hide vertical scrollbar
    maxHeight: "190px",
  }),
  menuList: (provided) => ({
    ...provided,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#0A5640",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    overflowX: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#2e2d2d"
      : state.isFocused
      ? "#2e2d2d"
      : "#2e2d2d", // Dark background for options
    color: "#fff", // White text

    "&:hover": {
      backgroundColor: "#2e2d2d", // Lighter background on hover
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#ffff", // White text for selected value
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(177, 177, 177)", // White text
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent", // Dark indicator separator
    // color: "#0000",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff", // White dropdown indicator
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff", // White text color in the search input
  }),
};

const EditProfilePage = ({ t }) => {
  const dispatch = useDispatch();
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [isMobileEditable, setIsMobileEditable] = useState(false);
  const [profileImage, setProfileImage] = useState(ProfileImage);
  const [options, setOptions] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showMobileOtpModal, setShowMobileOtpModal] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [mobileOtp, setMobileOtp] = useState(["", "", "", "", "", ""]);
  const [verifyToken, setVerifyToken] = useState("");
  const [verifyMobileToken, setVerifyMobileToken] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [otpDisabled, setOtpDisabled] = useState(false);
  const profilePic = useRef();
  const user = useSelector((state) => state.user);
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  useEffect(() => {
    console.log("defaultCountryCode updated to: ", defaultCountryCode);
  }, [defaultCountryCode]);
  const { data: countryCodeList } = useGetCountryCodeList();

  useEffect(() => {
    if (countryCodeList) {
      const countryOptions = countryCodeList.data.map((val) => ({
        value: val.country_code_id,
        label: `${val.phone_code} (${val.name})`,
      }));

      setOptions(countryOptions);

      setDefaultCountryCode(
        countryOptions.find((opt) => opt.label.includes("United States"))
          ?.value || ""
      );
    }
  }, [countryCodeList]);

  const { refetch: doGetProfile, isLoading } = useGetProfile(
    ({ data: profile }) => {
      if (profile) {
        formik.setValues({
          full_name: profile.user_data.full_name,
          email: profile.user_data.email,
          phone: profile.user_data.phone,
          country_code_id: profile.user_data.country_code_id,
          profile_image: profile.user_data.profile_image,
        });
        dispatch(updateUserData({ userData: profile.user_data }));
      }
    }
  );

  const { mutate: doUseUpdateProfile, isLoading: isUpdating } =
    useUpdateProfile((response) => {
      toast.success(response.message);
      doGetProfile();
      window.scrollTo(0, 0);
    });
  const { mutate: doResendOTP, isLoading: isLoadingResendOTP } =
    useResendEmailOtp((response) => {
      toast.success(response.message);
    });

  const { mutate: doUserUpdateEmail, isLoading: isLoadingEmail } =
    useUpdateEmail((response) => {
      toast.success(response.message);
      setVerifyToken(response.data.encoded_token);
      setShowEmailModal(false);
      setShowOtpModal(true);
    });

  const { mutate: doUserUpdateEmailVerify, isLoading: isLoadingEmailVerify } =
    useUpdateEmailVerify((response) => {
      toast.success(response.message);
      setShowOtpModal(false);
      setTimeout(() => {
        doGetProfile();
      }, 2000);
    });

  const { mutate: doSendOtpForMobile, isLoading: isLoadingSendOtp } =
    useSendOtpForMobile((response) => {
      setShowPhoneModal(false);
      toast.success(response.message);
      setVerifyMobileToken(response.data.encoded_token);
      setShowMobileOtpModal(true);
    });

  const handleEditEmail = () => {
    setShowEmailModal(true);
  };

  const handleAddMobileNumber = () => {
    setIsMobileEditable(!isMobileEditable);
    setSelectedCountryCode(
      options.find((opt) => opt.label.includes("United States"))?.value || ""
    );
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
      country_code_id: selectedCountryCode || "",
      profile_image: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (profilePic.current.files[0]) {
        values.profile_image = profilePic.current.files[0];
      }
      doUseUpdateProfile(values);
    },
  });

  const formikEmail = useFormik({
    initialValues: {
      newEmail: "",
    },
    validationSchema: validationSchemaEmail,
    onSubmit: () => {
      doUserUpdateEmail({ email: formikEmail.values.newEmail });
      formikEmail.resetForm();
    },
  });

  const formikMobile = useFormik({
    initialValues: {
      newMobile: "",
    },
    validationSchema: validationSchemaPhone,
    onSubmit: () => {
      doSendOtpForMobile({
        country_code_id: selectedCountryCode,
        phone: formikMobile.values.newMobile,
      });
      formikMobile.resetForm();
    },
  });

  const handleResendOTP = () => {
    const value = {
      encoded_token: verifyToken,
    };
    doResendOTP(value);
    setOtpDisabled(true);

    setTimeout(() => {
      setOtpDisabled(false);
    }, 60000);
  };
  const handleChange = (element, index) => {
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Auto focus to next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleMobileChange = (element, index) => {
    let newMobileOtp = [...mobileOtp];
    newMobileOtp[index] = element.value;
    setMobileOtp(newMobileOtp);

    // Auto focus to next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      // Clear the current input and focus the previous one
      if (index >= 0) {
        let newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        if (index > 0) {
          e.target.previousSibling.focus();
        }
      }
    }
  };

  const handleMobileKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      // Clear the current input and focus the previous one
      if (index > 0) {
        let newMobileOtp = [...mobileOtp];
        newMobileOtp[index] = "";
        setMobileOtp(newMobileOtp);
        e.target.previousSibling.focus();
      }
    }
  };

  const handleOtpSubmit = () => {
    doUserUpdateEmailVerify({
      verification_otp: otp.join(""),
      encoded_token: verifyToken,
    });
    setOtp(["", "", "", "", "", ""]); // Clear OTP fields
  };

  const handleMobileOtpSubmit = () => {
    alert(mobileOtp.join(""));
    setMobileOtp(["", "", "", "", "", ""]); // Clear OTP fields
  };
  function imagePreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      formik.setFieldValue("profile_image", e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  useEffect(() => {
    setProfileImage(user.user.profile_image);
  }, [user.user.profile_image]);
  return (
    <>
      <CommonHelmet
        title={"front_page.edit_profile_seo_title"}
        description={"front_page.edit_profile_seo_title"}
      />
      <div className="edit-profile">
        <h2>{t("front_page.edit_profile_my_profile_label")}</h2>
        <NavItemsCommon navItemName={EditEmailNavItems} t={t} />
        <div className="edit-profile-container">
          <Row>
            <Col xs={12} xl={4} className="image-container">
              <div className="image-content">
                <Image
                  roundedCircle
                  src={
                    profileImage.split("/").pop() !== "default.jpeg"
                      ? profileImage
                      : default_img
                  }
                  alt="profile_img"
                  className="profile-image"
                />
                <Form.Group id="formFile" className="mt-2">
                  <Form.Label className="image-add" htmlFor="profile_image">
                    {t("front_page.edit_profile_change_profile_photo_label")}
                  </Form.Label>
                  <Form.Control
                    name="profile_image"
                    id="profile_image"
                    onChange={imagePreview}
                    ref={profilePic}
                    style={{ visibility: "hidden", display: "none" }}
                    type="file"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} xl={8} className="user-details ps-md-5 ">
              <Form
                className="edit-profile-form"
                onSubmit={formik.handleSubmit}
              >
                <Form.Group id="formName" className="mb-3">
                  <Form.Label>
                    {t("front_page.edit_profile_name_label")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "front_page.edit_profile_enter_name_placeholder"
                    )}
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                    name="full_name"
                    isInvalid={
                      !!formik.errors.full_name && formik.touched.full_name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(formik.errors.full_name)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group id="formEmail" className="mb-3 position-relative">
                  <Form.Label>
                    {t("front_page.edit_profile_email_label")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      placeholder={t(
                        "front_page.edit_profile_enter_email_placeholder"
                      )}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      name="email"
                      readOnly={!isEmailEditable}
                      isInvalid={!!formik.errors.email && formik.touched.email}
                    />

                    <InputGroup.Text
                      onClick={handleEditEmail}
                      className="btn-edit-email"
                      style={{ cursor: "pointer" }}
                    >
                      <FaEdit />
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {t(formik.errors.email)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Text className="mb-3 d-block text-end">
                  <a
                    href="#"
                    className="text-white"
                    onClick={handleAddMobileNumber}
                  >
                    {isMobileEditable
                      ? t("front_page.edit_profile_edit_mobile_number_label")
                      : t("front_page.edit_profile_add_mobile_number_label")}
                  </a>
                </Form.Text>

                {isMobileEditable && (
                  <Form.Group id="formMobileNumber" className="mb-4">
                    <Form.Label>
                      {t("front_page.edit_profile_contact_number_label")} *
                    </Form.Label>
                    <div className="d-flex justify-content-between w-100">
                      <div className="contact-code-id">
                        <Select
                          options={options}
                          styles={customStyles}
                          name="country_code_id"
                          value={options.find(
                            (opt) => opt.value == formik.values.country_code_id
                          )}
                          onChange={(option) =>
                            formik.setFieldValue(
                              "country_code_id",
                              option.value
                            )
                          }
                          defaultValue={{
                            value: 227,
                            label: "1 (United States)",
                          }}
                          className="me-2 mb-md-0"
                        />
                      </div>
                      <div className="contact-mobile">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            className="bg-theme text-light contact-form-input"
                            name="phone"
                            placeholder={t(
                              "front_page.edit_profile_enter_mobile_number_placeholder"
                            )}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            isInvalid={
                              !!formik.errors.phone && formik.touched.phone
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(formik.errors.phone)}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </div>
                  </Form.Group>
                )}

                <Button
                  type="submit"
                  className="mt-3 w-100"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="ms-2">
                        {t("front_page.edit_profile_saving_button_label")}
                      </span>
                    </>
                  ) : (
                    t("front_page.edit_profile_save_button_label")
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>

        {/* Email Edit Modal */}
        <Modal
          show={showEmailModal}
          onHide={() => setShowEmailModal(false)}
          className="edit-profile-modal"
          centered
        >
          <Modal.Header closeButton className="d-flex flex-column-reverse">
            <Modal.Title className="mt-2 w-100">
              {t("front_page.edit_profile_edit_email_label")}
              <p>{t("front_page.edit_profile_otp_sent_message")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formikEmail.handleSubmit} noValidate>
              <Form.Group id="formNewEmail">
                <Form.Label>
                  {t("front_page.edit_profile_email_label")}
                </Form.Label>
                <Form.Control
                  type="email"
                  className="w-100"
                  placeholder={t(
                    "front_page.edit_profile_enter_new_email_placeholder"
                  )}
                  name="newEmail"
                  value={formikEmail.values.newEmail}
                  onChange={formikEmail.handleChange}
                  isInvalid={
                    !!formikEmail.errors.newEmail &&
                    formikEmail.touched.newEmail
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {t(formikEmail.errors.newEmail)}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer className="p-0 pt-3">
                <Button
                  className="w-100"
                  type="submit"
                  disabled={isLoadingEmail}
                >
                  {isLoadingEmail ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    t("front_page.edit_profile_send_verification_code_label")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        {/* OTP Verification Modal for Email */}
        <Modal
          show={showOtpModal}
          onHide={() => setShowOtpModal(false)}
          className="verify-otp-modal"
          centered
        >
          <Modal.Header closeButton className="d-flex flex-column-reverse">
            <Modal.Title className="mt-2 w-100">
              {t("front_page.edit_profile_otp_verification_label")}
              <p>{t("front_page.edit_profile_otp_received_message")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group id="formOtp">
              <Form.Label>
                {t("front_page.edit_profile_enter_otp_label")}
              </Form.Label>
              <div className="otp-input">
                {otp.map((_, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className="mx-1 text-center"
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="w-100"
              variant="primary"
              onClick={handleOtpSubmit}
              disabled={isLoadingEmailVerify}
            >
              {isLoadingEmailVerify ? (
                <Spinner animation="border" size="sm" />
              ) : (
                t("front_page.edit_profile_verify_button_label")
              )}
            </Button>
            <div className="w-100 ms-2">
              <span>
                {t("front_page.edit_profile_dont_receive_otp_message")}
                <a
                  href="#"
                  className="ms-1 text-dark"
                  onClick={otpDisabled ? null : handleResendOTP}
                  style={{
                    cursor: otpDisabled ? "not-allowed" : "pointer",
                    color: otpDisabled ? "#aaa" : "#094936",
                  }}
                >
                  {otpDisabled
                    ? t("front_page.edit_profile_resend_otp_sent_label")
                    : t("front_page.edit_profile_resend_otp_label")}
                </a>
              </span>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Mobile Edit Modal */}
        <Modal
          show={showPhoneModal}
          onHide={() => setShowPhoneModal(false)}
          className="edit-mobile-modal"
          centered
        >
          <Modal.Header closeButton className="d-flex flex-column-reverse">
            <Modal.Title className="w-100 mt-2">
              {t("front_page.edit_profile_add_mobile_number_label")}
              <p>{t("front_page.edit_profile_otp_sent_message")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formikMobile.handleSubmit}>
              <Row>
                <Col md={4}>
                  <Form.Group id="formCountryCode" className="mb-3">
                    <Form.Label>
                      {t("front_page.edit_profile_mobile_number_label")}
                    </Form.Label>
                    <Select
                      options={options}
                      styles={customStyles}
                      value={options.find(
                        (opt) => opt.value == selectedCountryCode
                      )}
                      onChange={(option) =>
                        setSelectedCountryCode(option.value)
                      }
                      className="me-2 mb-md-0 formCountryCode"
                    />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group id="formNewMobile">
                    <Form.Label>
                      {t("front_page.edit_profile_new_mobile_label")}
                    </Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder={t(
                        "front_page.edit_profile_enter_new_mobile_placeholder"
                      )}
                      name="newMobile"
                      value={formikMobile.values.newMobile}
                      onChange={formikMobile.handleChange}
                      isInvalid={
                        !!formikMobile.errors.newMobile &&
                        formikMobile.touched.newMobile
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formikMobile.errors.newMobile)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer className="p-3">
                <Button className="w-100" variant="primary" type="submit">
                  {t("front_page.edit_profile_send_button_label")}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        {/* OTP Verification Modal for Mobile */}
        <Modal
          show={showMobileOtpModal}
          onHide={() => setShowMobileOtpModal(false)}
          className="verify-otp-modal"
          centered
        >
          <Modal.Header closeButton className="d-flex flex-column-reverse">
            <Modal.Title className="w-100 mt-2">
              {t("front_page.edit_profile_otp_verification_label")}
              <p>{t("front_page.edit_profile_otp_received_message")}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group id="formNewEmail">
              <Form.Label>
                {t("front_page.edit_profile_enter_otp_label")}
              </Form.Label>
              <div className="otp-input">
                {mobileOtp.map((_, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className="mx-1 text-center"
                    value={mobileOtp[index]}
                    onChange={(e) => handleMobileChange(e.target, index)}
                    onKeyDown={(e) => handleMobileKeyDown(e, index)}
                  />
                ))}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowMobileOtpModal(false)}
            >
              {t("front_page.edit_profile_close_button_label")}
            </Button>
            <Button variant="primary" onClick={handleMobileOtpSubmit}>
              {t("front_page.edit_profile_verify_otp_button_label")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditProfilePage;
