import React from "react";

function HomeIcon() {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 19.5H9V13.5H15V19.5H18V10.5L12 6L6 10.5V19.5ZM4 21.5V9.5L12 3.5L20 9.5V21.5H13V15.5H11V21.5H4Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default HomeIcon;
