import React from "react";

const FacebookIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2812_2529)">
      <path
        d="M24.0962 12.328C24.0962 5.68666 18.7123 0.302734 12.0711 0.302734C5.42979 0.302734 0.0458984 5.68666 0.0458984 12.328C0.0458984 18.3301 4.44335 23.3051 10.1921 24.2071V15.804H7.13886V12.328H10.1921V9.67867C10.1921 6.66484 11.9874 5.0001 14.7342 5.0001C16.0499 5.0001 17.426 5.23496 17.426 5.23496V8.1943H15.9097C14.4159 8.1943 13.95 9.12128 13.95 10.0723V12.328H17.2851L16.7519 15.804H13.95V24.2071C19.6988 23.3051 24.0962 18.3302 24.0962 12.328Z"
        fill="#1877F2"
      />
      <path
        d="M16.7532 15.8059L17.2864 12.3298H13.9513V10.0742C13.9513 9.12304 14.4171 8.19616 15.911 8.19616H17.4273V5.23682C17.4273 5.23682 16.0512 5.00195 14.7354 5.00195C11.9887 5.00195 10.1934 6.6667 10.1934 9.68053V12.3298H7.14014V15.8059H10.1934V24.209C10.815 24.3064 11.4432 24.3552 12.0723 24.3551C12.7015 24.3552 13.3297 24.3064 13.9513 24.209V15.8059H16.7532Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2812_2529">
        <rect
          width="24.0503"
          height="24.0505"
          fill="white"
          transform="translate(0.0458984 0.300781)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookIcon;
