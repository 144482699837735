export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const OTP_VERIFICATION = "/otp-verification";
export const HOME = "/";
export const ABOUT = "/about";
export const CONTACT = "/contact";
export const FAQ = "/faq";
export const POST_ADVERTISEMENT = "/post-advertisement";
export const ABCD = "/abcd";
export const USER_PROFILE_VIEW = "/user-profile-view";
export const CMS_ROUTES = "/page";
export const DASHBOARD = "/dashboard";
export const EDIT_PROFILE = "/edit-profile";
export const CHANGE_PASSWORD = "/change-password";
export const DEACTIVATE_ACCOUNT = "/deactivate-account";
export const MANAGE_CARD = "/manage-card";
export const BECOME_BUSINESS_USER = "/become-business-user";
export const EDIT_BECOME_BUSINESS_USER = "/edit-become-business-user";
export const PAYMENT_HISTORY = "/payment-history";
export const ADS = "/ads";
export const NOTIFICATIONS = "/notifications";
export const CHAT = "/chat";

export const VIEW_ADS = "/view-ads";
export const CREATE_ADS = "/create-ads";
export const CHECKOUT = "/ads_checkout";
export const SELECT_CATEGORY = "/select-category";
export const SELECT_SUB_CATEGORY = "/select-sub-category";
export const PREVIEW = "/preview";
export const LIST_ADS = "/list-ads";
export const EDIT_ADS = "/edit-ads";
export const COMPLETED = "/completed";
export const CANCELLED = "/cancelled";
export const FAVORITES = "/favorites";
export const POST_LIST = "/post-list";
export const PRIVACY = "/privacy-policy";
export const TERMS = "/terms-conditions";
