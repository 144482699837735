import React from "react";

const EmailIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2812_2522)">
      <path
        d="M16.5625 12.2057L22.7264 6.85043V17.4998L16.5625 12.2057ZM8.61988 13.1126L10.7844 14.9915C11.1241 15.2801 11.567 15.4545 12.0511 15.4545H12.0681H12.0811C12.5661 15.4545 13.0091 15.2791 13.3528 14.9885L13.3498 14.9905L15.5143 13.1116L22.0941 18.7624H2.04313L8.61988 13.1126ZM2.03411 5.58076H22.1061L12.4649 13.9543C12.357 14.0409 12.2225 14.0877 12.0841 14.0866H12.0701H12.0571C11.9182 14.0876 11.7833 14.0405 11.6753 13.9533L11.6763 13.9543L2.03411 5.58076ZM1.41181 6.84943L7.5747 12.2047L1.41181 17.4958V6.84943ZM23.0571 4.40329C22.8166 4.28304 22.534 4.21289 22.2344 4.21289H1.90684C1.61652 4.21404 1.33031 4.28157 1.07009 4.41031L1.08111 4.40529C0.770331 4.5591 0.508614 4.79655 0.325389 5.09095C0.142164 5.38535 0.0446962 5.72503 0.0439453 6.07179L0.0439453 18.2694C0.0444758 18.763 0.24081 19.2363 0.589868 19.5854C0.938926 19.9344 1.4122 20.1308 1.90584 20.1313H22.2314C22.725 20.1308 23.1983 19.9344 23.5473 19.5854C23.8964 19.2363 24.0927 18.763 24.0933 18.2694V6.07179C24.0933 5.34326 23.6734 4.71194 23.0621 4.4083L23.0511 4.40329H23.0571Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2812_2522">
        <rect
          width="24.0503"
          height="24.0505"
          fill="white"
          transform="translate(0.0439453 0.203125)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIcon;
