import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("front_page.contact_name_required"),

  mobile_number: Yup.string()
    .length(10, "front_page.contact_number_length")
    .matches(/^[0-9]+$/, "front_page.contact_number_digits_only")
    .required("front_page.contact_number_required"),

  country_code_id: Yup.string().required("front_page.contact_code_required"),

  email: Yup.string("front_page.contact_email_required")
    .email("front_page.contact_email_invalid")
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "front_page.contact_email_invalid"
    )
    .required("front_page.contact_email_required"),

  message: Yup.string().required("front_page.contact_message_required"),
});
export default validationSchema;
