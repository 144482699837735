import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import "assets/scss/page/_notifications.scss";
import {
  useDeleteNotification,
  useGetNotifications,
  useReadAllNotification,
} from "hooks";
import { setNotifications, addNotification } from "store";
import { CommonHelmet } from "common";

const Notifications = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(
    (state) => state.notifications?.notifications
  );

  const { refetch } = useGetNotifications((res) => {
    dispatch(setNotifications(res.data));
  });
  const { mutate: doDelete } = useDeleteNotification(
    (res) => {
      if (res) {
        refetch();
      }
    },
    (error) => {
      console.error(error);
    }
  );
  const { mutate: doReadAll } = useReadAllNotification(
    (res) => {
      if (res) {
        refetch();
      }
    },
    (error) => {
      console.error(error);
    }
  );

  useEffect(() => {
    refetch();

    const handlePushNotification = (event) => {
      const { title, message, description, redirection_url } = event.data;
      const newNotification = { title, message, description, redirection_url };
      dispatch(addNotification(newNotification));
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener(
        "message",
        handlePushNotification
      );
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener(
          "message",
          handlePushNotification
        );
      }
    };
  }, [dispatch, refetch]);

  const handleRedirection = (id, path) => {
    doReadAll({ notification_id: id, status: 1 });
    navigate(path);
  };
  const handleDelete = (id, status) => {
    doDelete({ notification_id: id, status });
  };
  const handleRead = (id, status) => {
    doReadAll({ notification_id: id, status });
  };

  return (
    <>
      <CommonHelmet
        title={"front_page.notification_seo_title"}
        description={"front_page.notification_seo_title"}
      />
      <section id="content-wrapper">
        <div className="notification-list py-0">
          <div className="my-3 text-end">
            {notifications?.length > 0 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() =>
                    handleDelete(notifications[0].notification_id, 2)
                  }
                >
                  {t("front_page.notification_delete_all_btn")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() =>
                    handleRead(notifications[0].notification_id, 0)
                  }
                >
                  {t("front_page.notification_read_all_btn")}
                </button>
              </>
            )}
          </div>
          <div className="my-3">
            <h2>{t("front_page.notification_header_txt")}</h2>
          </div>
          {notifications?.length === 0 ? (
            <div className="mt-3 notification-card">
              <div className="notification-card-body text-center">
                <h4>{t("front_page.notification_no_notification_txt")}</h4>
              </div>
            </div>
          ) : (
            notifications?.map((notification, index) => (
              <div
                key={index}
                className={`mt-3 notification-card ${
                  notification?.is_read == 2 ? "not-read" : ""
                }`}
              >
                <div className="notification-card-body">
                  <Row>
                    <Col md={9}>
                      <h5>
                        <b>{notification.title}</b>
                      </h5>
                      <p>{notification.message}</p>
                      {notification.description && (
                        <p>{notification.description}</p>
                      )}
                    </Col>
                    <Col md={3}>
                      <div className="d-flex justify-content-end sub-title-text mt-2">
                        {moment(notification.created_at).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </div>
                      <div className="d-flex justify-content-end align-items-center mt-3">
                        {notification.redirection_url != null && (
                          <button
                            type="button"
                            className="me-2 py-1 btn btn-primary"
                            onClick={() =>
                              handleRedirection(
                                notification?.notification_id,
                                notification.redirection_url
                              )
                            }
                          >
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="eye"
                              className="svg-inline--fa fa-eye "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                              ></path>
                            </svg>
                          </button>
                        )}
                        <button
                          type="button"
                          className=" py-1 dlt-card-btn btn btn-primary"
                          onClick={() =>
                            handleDelete(notification.notification_id, 1)
                          }
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="trash-can"
                            className="svg-inline--fa fa-trash-can "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};

export default Notifications;
