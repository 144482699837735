import client from "libs/HttpClient";
class NotificationsService {
  static getNotificationsData() {
    return client.get("notifications/get");
  }
  static deleteNotification(request) {
    return client.post("notifications/delete", request);
  }

  static readAllNotifications(request) {
    return client.post("notifications/read-all", request);
  }
}

export { NotificationsService };
