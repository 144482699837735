import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import Checkout from "pages/Checkout/Checkout";
import {
  CANCELLED,
  CHECKOUT,
  COMPLETED,
  CREATE_ADS,
  EDIT_ADS,
  FAVORITES,
  LIST_ADS,
  PREVIEW,
  SELECT_CATEGORY,
  SELECT_SUB_CATEGORY,
  VIEW_ADS,
} from "config/routes";

const CreateAdsPage = React.lazy(() => import("./CreateAdsPage"));
const SelectCategory = React.lazy(() => import("./SelectCategoryPage"));
const SelectSubCategory = React.lazy(() => import("./SelectSubCategoryPage"));
const AdsPreview = React.lazy(() => import("./AdsPreview"));
const ListAds = React.lazy(() => import("./ListAdsPage"));
const EditAds = React.lazy(() => import("./EditAdsPage"));
const ViewAds = React.lazy(() => import("./ViewAdsPage"));
const AdsCompletedListPage = React.lazy(() => import("./AdsCompletedListPage"));
const AdsCancelledListPage = React.lazy(() => import("./AdsCancelledListPage"));
const AdsFavoriteListPage = React.lazy(() => import("./AdsFavoriteListPage"));
const AdsRoutes = ({ getSettingData, t }) => {
  return (
    <Routes>
      <Route
        exact
        path={CREATE_ADS}
        element={<CreateAdsPage getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={SELECT_CATEGORY}
        element={<SelectCategory getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={SELECT_SUB_CATEGORY}
        element={<SelectSubCategory getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={PREVIEW}
        element={<AdsPreview getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={LIST_ADS}
        element={<ListAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={EDIT_ADS}
        element={<EditAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={VIEW_ADS}
        element={<ViewAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={CHECKOUT}
        element={<Checkout getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={COMPLETED}
        element={<AdsCompletedListPage getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={CANCELLED}
        element={<AdsCancelledListPage getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={FAVORITES}
        element={<AdsFavoriteListPage getSettingData={getSettingData} t={t} />}
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AdsRoutes.propTypes = {
  t: PropTypes.func,
};
export default AdsRoutes;
