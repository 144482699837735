import client from "../libs/HttpClient";
class UserService {
  static getProfile(request) {
    return client.get("user/get-profile", request);
  }
  static updateProfile(request) {
    return client.post("user/edit-profile", request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }
  static updateEmail(request) {
    return client.put("user/add-new-email", request);
  }
  static updateEmailVerify(request) {
    return client.put("user/verify-new-email", request);
  }
  static resendOtp(request) {
    return client.put("user/verify-resend-email", request);
  }
  static sendOtpForMobile(request) {
    return client.post("user/send-otp-for-mobile", request);
  }
  static changePassword(request) {
    return client.post("user/change-password", request);
  }
  static deactivateAccount(request) {
    return client.put("user/deactivate-account", request);
  }
}
export { UserService };
