import React from "react";

function UserProfileNameIcon() {
  return (
    <>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 17.0003C15.4417 17.0003 14.1077 16.4455 12.9979 15.3357C11.8882 14.226 11.3334 12.892 11.3334 11.3337C11.3334 9.77533 11.8882 8.4413 12.9979 7.33158C14.1077 6.22185 15.4417 5.66699 17 5.66699C18.5584 5.66699 19.8924 6.22185 21.0021 7.33158C22.1118 8.4413 22.6667 9.77533 22.6667 11.3337C22.6667 12.892 22.1118 14.226 21.0021 15.3357C19.8924 16.4455 18.5584 17.0003 17 17.0003ZM5.66669 28.3337V24.367C5.66669 23.5642 5.87328 22.8264 6.28648 22.1535C6.69967 21.4805 7.24863 20.967 7.93335 20.6128C9.39724 19.8809 10.8847 19.3319 12.3959 18.966C13.907 18.6 15.4417 18.417 17 18.417C18.5584 18.417 20.0931 18.6 21.6042 18.966C23.1153 19.3319 24.6028 19.8809 26.0667 20.6128C26.7514 20.967 27.3004 21.4805 27.7136 22.1535C28.1268 22.8264 28.3334 23.5642 28.3334 24.367V28.3337H5.66669ZM8.50002 25.5003H25.5V24.367C25.5 24.1073 25.4351 23.8712 25.3052 23.6587C25.1754 23.4462 25.0042 23.2809 24.7917 23.1628C23.5167 22.5253 22.2299 22.0472 20.9313 21.7285C19.6327 21.4097 18.3222 21.2503 17 21.2503C15.6778 21.2503 14.3674 21.4097 13.0688 21.7285C11.7702 22.0472 10.4834 22.5253 9.20835 23.1628C8.99585 23.2809 8.82467 23.4462 8.69481 23.6587C8.56495 23.8712 8.50002 24.1073 8.50002 24.367V25.5003ZM17 14.167C17.7792 14.167 18.4462 13.8896 19.0011 13.3347C19.5559 12.7798 19.8334 12.1128 19.8334 11.3337C19.8334 10.5545 19.5559 9.88748 19.0011 9.33262C18.4462 8.77776 17.7792 8.50033 17 8.50033C16.2209 8.50033 15.5538 8.77776 14.999 9.33262C14.4441 9.88748 14.1667 10.5545 14.1667 11.3337C14.1667 12.1128 14.4441 12.7798 14.999 13.3347C15.5538 13.8896 16.2209 14.167 17 14.167Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default UserProfileNameIcon;
