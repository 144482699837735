import client from "../libs/HttpClient";
class PaymentService {
  static addCard(request) {
    return client.post("payment/add-card", request);
  }
  static getCards() {
    return client.get("payment/get-cards");
  }
  static deleteCard(request) {
    return client.post("payment/delete-card", request);
  }
  static createPayment(request) {
    return client.post("payment/create-payment-intent", request);
  }
  static getPaymentHistory({ params }) {
    return client.get("payment/get-payment-history", { params });
  }
  static getPaymentIntent({ params }) {
    return client.get("payment/get-payment-intent", { params });
  }
}
export { PaymentService };
